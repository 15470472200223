<template>
  <div>
    <v-card flat v-if="selectedPort" color="modal" style="height: 100%;">
      <v-toolbar class="tooly" flat dense>
        <div style="display: flex;flex-direction: column;justify-content: start;align-items: start; height: 100%">
          <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs" small class="pl-0">
              <v-avatar size="32" class="mr-2">
                <v-img
                  v-if="selectedPort.countryCode"
                  contain
                  :src="`https://cdn.loglive.io/flags/4x3/${selectedPort.countryCode.toLowerCase()}.svg`"
                >
                </v-img>
              </v-avatar>
              {{ selectedPort.name }}
            </v-chip>
          </template>
          <v-card>
            <v-subheader style="font-size: 12px; height: 36px">
              Favourite Ports
            </v-subheader>
            <v-progress-linear
              v-if="loadingFavourites"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-list
              v-if="!loadingFavourites"
              id="fav-port"
              class="scroll py-0"
              dense
            >
              <v-list-item
                dense
                v-for="fav in favourites"
                :key="fav.id"
                :style="{
                  border: '0.5px solid grey',
                  'border-left':
                    selectedPort && selectedPort.id == fav.unLocode.id
                      ? '3px solid var(--v-primary-base)'
                      : '0.5px solid grey',
                }"
                @click="selectedPort = fav.unLocode"
              >
                <v-list-item-action>
                  <v-avatar size="32">
                    <v-img
                      v-if="fav.unLocode.countryCode"
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${fav.unLocode.countryCode.toLowerCase()}.svg`"
                    >
                    </v-img>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ fav.unLocode.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ fav.unLocode.locode }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="favourites.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey">No favourites</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-subheader style="font-size: 12px; height: 36px">
              Suggested Ports
            </v-subheader>
            <v-progress-linear
              v-if="loadingSuggestions"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-list
              dense
              v-if="!loadingSuggestions"
              id="suggested-port"
              class="scroll py-0"
              max-height="48vh"
            >
              <v-list-item
                dense
                v-for="suggested in suggestedPorts"
                :key="suggested.id"
                :style="{
                  border: '0.5px solid grey',
                  'border-left':
                    selectedPort && selectedPort.id == suggested.id
                      ? '3px solid var(--v-primary-base)'
                      : '0.5px solid grey',
                }"
                @click="selectedPort = suggested"
              >
                <v-list-item-action>
                  <v-avatar size="32">
                    <v-img
                      v-if="suggested.countryCode"
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${suggested.countryCode.toLowerCase()}.svg`"
                    >
                    </v-img>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ suggested.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ suggested.locode }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="suggestedPorts.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey">No suggestions</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          </v-menu>
          <span style="font-size: 12px; opacity: 0.7">{{ selectedPort.locode }}</span>
      </div>
        <v-spacer></v-spacer>
        <div v-if="portInfo.congestion && (portInfo.congestion.congestion || portInfo.congestion.gapWithMean)"
          style="height: 100%; display: flex; flex-direction: column; justify-content: start; align-items: end;">
          <v-tooltip top offset-y open-delay="200">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" class="pr-0" small>
                Estimated Port Congestion
                <v-chip
                  small
                  class="px-2 ml-2"
                  color="white"
                  style="color: var(--v-greyBase-darken1)"
                  ><b>{{ portInfo.congestion.congestion }}%</b></v-chip
                >
              </v-chip>
            </template>
            Last Updated:
            {{ formatDateTime(portInfo.congestion.time) }}
          </v-tooltip>
          <span
            v-if="portInfo.congestion.gapWithMean > 0"
            style="border: none; color: var(--v-redPop-base); font-size: 10px"
          >
            <v-icon color="redPop" small> arrow_drop_up </v-icon>
            {{ portInfo.congestion.gapWithMean }}% above average
          </span>
          <span
            v-else-if="portInfo.congestion.gapWithMean < 0"
            style="border: none; color: var(--v-success-base); font-size: 10px"
          >
            <v-icon color="success" small> arrow_drop_down </v-icon>
            {{ portInfo.congestion.gapWithMean }}% below average
          </span>
        </div>
      </v-toolbar>
      <v-card-text class="pa-0" style="height: calc(100% - 64px) !important; overflow-y: scroll; ">
        <div
          v-if="portInfo.voyages && portInfo.voyages.length > 0"
        >
          <v-list
            dense
            subheader
            v-for="(week, index) in portInfo.voyages"
            :key="index"
          >
            <v-divider></v-divider>
            <v-subheader>Week: {{ week.week }}</v-subheader>
            <v-divider></v-divider>
            <div v-for="voyage in week.voyages" :key="voyage.id" style="border-bottom: 0.25px solid grey">
              <v-list-item class="pl-1">
                <v-list-item-avatar v-if="voyage.vessel" class="denseAvatar">
                  <v-avatar
                    size="48"
                    class="mr-0"
                    :color="
                      voyage.vessel.countryCode ? 'transparent' : 'blue-grey'
                    "
                  >
                    <v-img
                      v-if="voyage.vessel.countryCode"
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${voyage.vessel.countryCode.toLowerCase()}.svg`"
                    >
                    </v-img>
                    <v-icon v-else color="white" small>
                      directions_boat
                    </v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-if="voyage.vessel">
                    {{ voyage.vessel.name }}
                    <span v-if="voyage.importVoyage" style="color: grey">
                      - {{ voyage.importVoyage }}</span
                    >
                    <span
                      style="color: grey"
                      v-if="
                        voyage.exportVoyage &&
                        voyage.exportVoyage != voyage.importVoyage
                      "
                    >
                      - {{ voyage.exportVoyage }}</span
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">
                    Operator:
                    <span style="color: grey">
                      {{ voyage.operator }}
                      <span v-if="voyage.service"> - {{ voyage.service }}</span>
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle style="font-size: 10px;">
                      <v-icon
                        x-small
                        :color="voyage.isATA ? 'success' : 'orange'"
                        >schedule</v-icon
                      >
                      ETA: {{ formatDateTime(voyage.eta) }}
                      <v-icon class="ml-2"
                        x-small
                        :color="voyage.isATD ? 'success' : 'orange'"
                        >schedule</v-icon
                      >
                      ETD: {{ formatDateTime(voyage.etd) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <div :key="stackKey">
                <v-list dense class="pa-0">
                  <v-list-item class="pl-1"
                    v-for="stack in voyage.rmsVesselVoyageStacks"
                    :key="stack.id"
                  >
                  <v-list-item-avatar class="denseAvatar">
                    <v-icon small>anchor</v-icon>
                  </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="mb-0">
                        {{ stack.terminal }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="mb-0" v-if="stack.reeferStackStart" style="font-size: 10px;">
                        <v-icon x-small color="blue" class="mr-2">ac_unit</v-icon>
                          Reefer Stacks
                          <span style="font-size: 10px;" class="ml-2" >
                            <v-icon
                              x-small
                              :color="
                                stack.reeferStackStartConfirmed
                                  ? 'success'
                                  : 'orange'
                              "
                              >fiber_manual_record</v-icon
                            >
                            {{ formatISODate(stack.reeferStackStart) }}
                          </span>
                          <span style="font-size: 10px;" v-if="stack.reeferStackFinish" class="ml-2">
                            <v-icon
                              x-small
                              :color="
                                stack.reeferStackFinishConfirmed
                                  ? 'success'
                                  : 'orange'
                              "
                              >fiber_manual_record</v-icon
                            >
                            {{ formatISODate(stack.reeferStackFinish) }}
                          </span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="mb-0" v-if="stack.dryStackFinish" style="font-size: 10px;">
                        <v-icon x-small color="red" class="mr-2">widgets</v-icon>
                          Dry Stacks
                          <span style="font-size: 10px; margin-left: 23px !important;" >
                            <v-icon
                              x-small
                              :color="
                                stack.dryStackStartConfirmed
                                  ? 'success'
                                  : 'orange'
                              "
                              >fiber_manual_record</v-icon
                            >
                            {{ formatISODate(stack.dryStackStart) }}
                          </span>
                          <span style="font-size: 10px;" v-if="stack.dryStackFinish" class="ml-2">
                            <v-icon
                              x-small
                              :color="
                                stack.dryStackFinishConfirmed
                                  ? 'success'
                                  : 'orange'
                              "
                              >fiber_manual_record</v-icon
                            >
                            {{ formatISODate(stack.dryStackFinish) }}
                          </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-list>
        </div>
        <div v-else-if="!portInfo.voyages || portInfo.voyages.length == 0" style="height: 100%; width: 100%;" class="d-flex justify-center align-center">
          No data available
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import dateFormat from "dateformat";
import vesselJSON from "./vessels.json";
export default {
  props: {
    widget: {
      type: String,
      required: true,
    },
    columns: {
      type: Number,
      required: true,
    },
    rows: {
      type: Number,
      required: true,
    },
    widgetIndex: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    displayArrivalsDepartures: true,
    displayExpectedArrivals: true,
    displayWeatherInfo: true,
    displayStackDates: true,
    displayTerminalInfo: true,
    displayVesselsInPort: true,
    expectedArrivalHeaders: [
      { text: "Vessel", value: "shipName" },
      { text: "Scheduled Arrival", value: "reportedETA" },
    ],
    favourites: [],
    loadingFavourites: false,
    loadingPorts: false,
    loadingPortInfo: false,
    loadingSuggestions: false,
    openStacks: [],
    options: {
      list: {
        windowed: 600,
      },
      position: {
        start: 0,
      },
      autoplay: { play: false, repeat: false, speed: 2500 },
    },
    portInfoLoaded: true,
    portSearch: null,
    ports: [],
    portInfo: {},
    recentHeaders: [
      { text: "Vessel", value: "shipName" },
      { text: "Arrival", value: "arrival" },
      { text: "Departure", value: "departure" },
    ],
    selectedPort: null,
    searchTimer: null,
    stackDateHeaders: [
      { text: "Vessel", value: "vesselName" },
      { text: "Status", value: "stackStatus" },
      { text: "Stack Open", value: "stackOpen" },
      { text: "Stack Close", value: "stackClose" },
    ],
    stackKey: 100,
    suggestedPorts: [],
    terminalHeaders: [
      { text: "Terminal", value: "name" },
      { text: "No. Berths", value: "numberOfBerths" },
      { text: "No. Vessels", value: "numberOfVessels" },
    ],
    vesselsInPortHeaders: [
      { text: "Flag", value: "flag", sortable: false },
      { text: "Vessel", value: "shipName" },
    ],
    updatingFavourite: false,
    weatherKey: 1000,
    w: 1,
    h: 1,
    cellWidth: 0,
    cellHeight: 0,
  }),
  mounted() {
    this.$nextTick(() => {
      const path = this.widget;
      const grid = document.getElementById("grid");
      const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
      let gridWidth = grid.offsetWidth;
      let gridHeight = grid.offsetHeight;
      let cellWidth = gridWidth / 25;
      this.cellWidth = cellWidth;
      let cellHeight = gridHeight / 14;
      this.cellHeight = cellHeight;
      const gridResizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          gridWidth = entry.contentRect.width;
          gridHeight = entry.contentRect.height;
          cellWidth = gridWidth / 25;
          this.cellWidth = cellWidth;
          cellHeight = gridHeight / 14;
          this.cellHeight = cellHeight;
        }
      });
      gridResizeObserver.observe(grid, { box: "content-box" });
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const widgetWidth = entry.contentRect.width;
          const widgetHeight = entry.contentRect.height;
          const widgetColumns = Math.round(widgetWidth / cellWidth);
          this.w = widgetColumns;
          const widgetRows = Math.round(widgetHeight / cellHeight);
          this.h = widgetRows;
          console.log(`Widget: ${widgetColumns} cols x ${widgetRows} rows`);
        }
      });
      resizeObserver.observe(widget, { box: "content-box" });
    });
    // DO NOT CHANGE THE CODE ABOVE!!!
  },
  watch: {
    columns() {
      this.w = this.columns;
    },
    rows() {
      this.h = this.rows;
    },
    async portSearch(val) {
      if (val) {
        await this.getMonitoredPorts();
      }
    },
    "selectedPort.locode": {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadPortInfo();
        }
      },
    },
  },
  created() {
    this.getFavouritePorts();
    this.getSuggestedPorts();
  },

  computed: {},
  methods: {
    gridTemplateColumns() {
      return `repeat(6, minmax(${this.cellWidth}px, 1fr)`;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    formatISODate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getFavouritePorts() {
      this.loadingFavourites = true;
      this.favourites = await this.$API.getFavouritePorts();
      if (
        this.favourites.length > 0 &&
        (!this.selectedPort || !this.selectedPort.id)
      ) {
        this.selectedPort = this.favourites[0].unLocode;
      }
      this.loadingFavourites = false;
    },
    async getSuggestedPorts() {
      this.loadingSuggestions = true;
      this.suggestedPorts = await this.$API.getSuggestedPorts();
      this.loadingSuggestions = false;
    },
    async getMonitoredPorts() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loadingPorts = true;
      this.searchTimer = setTimeout(async () => {
        this.ports = await this.$API.getPorts({
          params: {
            search: this.portSearch,
          },
        });
        this.loadingPorts = false;
      }, 500);
    },
    async loadPortInfo() {
      this.loadingPortInfo = true;
      this.portInfoLoaded = false;
      // this.portInfo = await this.$API.getPortInfo(this.selectedPort.id);
      this.portInfo = vesselJSON;
      console.log(this.portInfo);
      this.portInfoLoaded = true;
      this.loadingPortInfo = false;
    },
    viewStacks(voyage) {
      let index = this.openStacks.indexOf(voyage.id);
      if (index == -1) {
        this.openStacks.push(voyage.id);
      } else {
        this.openStacks.splice(index, 1);
      }
      this.stackKey++;
    },
  },
};
</script>  

<style scoped>
.topInfoGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr;
}

.gridItem_span_2 {
  grid-column: span 2;
}
.denseAvatar.v-avatar.v-list-item__avatar {
  margin-right: 8px !important;
}
.tooly :is(.v-toolbar__content) {
    padding: 4px 4px !important;
}
</style>