
<template>
  <div> 
    <v-card v-if="documentItem && documentItem.id" style="height: 100vh" :key="documentUpdateKey">
      <v-toolbar flat>
        <!-- {{documentUpdateKey}} -->
        <v-toolbar-title>

          <v-menu offset-y v-if="documentItem.status != 'Submitted'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small active-class="activeClass" dark :depressed="false" v-bind="attrs" v-on="on" class="mr-0"
                :color="getStatusColor(documentItem.status)">
                {{ documentItem.status }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(status, index) in statusOptions.filter(s => s != 'Complete')" :key="index" @click="updateStatus(status, 1)" style="height: 30px">
                <v-list-item-action class="mr-1">
                  <v-icon small :color="getStatusColor(status)">fiber_manual_record</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{ status }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small active-class="activeClass" dark :depressed="false" v-bind="attrs" v-on="on" class="mr-0"
                :color="getStatusColor(documentItem.status)">
                {{ documentItem.status }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(status, index) in statusOptions.filter(s=>s!=documentItem.status)" :key="index" @click="updateStatus(status, 1)" style="height: 30px">
                <v-list-item-action class="mr-1">
                  <v-icon small :color="getStatusColor(status)">fiber_manual_record</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{ status }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-if="!shipment || (shipment && shipment.documentationStatus == 'OPEN') && documentItem.status != 'Complete'"
            icon small active-class="activeClass" class="ml-0" :color="getStatusColor(documentItem.status)"
            @click="nextStatus(documentItem)"><v-icon>chevron_right</v-icon></v-btn>

          {{ documentItem.id }} ({{ fileNumber }}) : {{ documentItem.locationDocument.name }}{{
            documentItem.preferredVariation ? ` - ${documentItem.preferredVariation.name}` : '' }}

          <v-btn icon @click="expandedProfile = !expandedProfile">
            <v-icon>{{
              expandedProfile ? "expand_less" : "expand_more"
            }}</v-icon>
          </v-btn>
          <v-chip v-if="shipment.forwarderId === $store.state.currentOrg.id" v-model="documentItem.isBillable"
            @click="updateIsBillable" :color="documentItem.isBillable ? 'green' : 'red'"
            :outlined="documentItem.isBillable ? false : true">
            Billable
          </v-chip>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="redPop" @click="closeModal(false)"><v-icon small>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-expand-transition>
          <div v-show="expandedProfile">
            <p>{{ documentItem.locationDocument.description }}</p>
          </div>
        </v-expand-transition>

        <!-- <v-tabs v-model="documentTab" show-arrows>
          <v-tab href="#info">Info</v-tab>
          <v-tab href="#media"><v-chip small class="mr-2">{{
            documentMedia(documentItem).length
          }}</v-chip>
            Media
          </v-tab>
          <v-tab href="#tasks" v-if="documentTasks(documentItem).length > 0"><v-chip small class="mr-2"
              v-if="documentTasks(documentItem).length > 0">{{ documentTasks(documentItem).length }}</v-chip>
            Tasks</v-tab>
        </v-tabs> -->
        <v-tabs-items v-model="documentTab">
          <v-tab-item value="info">
            <v-card flat style="border-radius: 0px">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-list dense subheader>
                      <v-text-field id="document-ref" class="my-0 py-0" label="Document Reference"
                        v-model="documentItem.reference" outlined dense clearable @change="updateReference()"></v-text-field>
                        <v-select label="Responsible Party" item-text="name" item-value="value"
                          v-model="documentItem.responsiblePartyType" @change="updateResponsibleParty()" outlined dense :items="[
                            { name: 'Shipper', value: 'shipper' },
                            { name: 'Forwarder', value: 'forwarder' },
                            { name: 'Consignee', value: 'consignee' },
                            { name: 'Buyer', value: 'buyer' },
                          ]"></v-select>
                          <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" id="document-sub-date"
                        :disabled="dateSelectionDisabled('submitted')">
                        <v-list-item-action>
                          <v-icon color="grey">event_note</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Submission Due Date
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="documentItem.submittedDate">
                            Actual Submitted Date
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          {{ documentItem.submittedDue }} <br v-if="documentItem.submittedDate">
                          <span v-if="documentItem.submittedDate"
                            :style="{ 'color': documentItem.submittedDate <= documentItem.submittedDue ? 'green' : 'red' }">{{
                              documentItem.submittedDate }}</span>
                        </v-list-item-action-text>
                      </v-list-item>
                    </template>
                      <v-date-picker
                          v-model="documentItem.submittedDate"
                          @change="updateSubmissionDate"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu> 
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                      <v-list-item v-on="on" id="document-sub-date"
                        :disabled="dateSelectionDisabled('resubmitted')">
                        <v-list-item-action>
                          <v-icon color="grey">event_note</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Resubmit Date
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          <span :style="{ 'color': documentItem.resubmitDate ? 'orange' : 'grey' }">{{
                            documentItem.resubmitDate
                          }} </span>
                        </v-list-item-action-text>
                      </v-list-item>
                      </template>
                      <v-date-picker
                          v-model="documentItem.resubmitDate"
                          @change="updateResubmitDate()"
                          no-title
                          scrollable
                          color="primary"
                        >
                        </v-date-picker>
                      </v-menu> 
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on" id="document-comp-date"
                        >
                        <v-list-item-action>
                          <v-icon color="grey">event_available</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Completion Due Date
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="documentItem.completedDate">
                            Actual Completion Date
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action-text>
                          {{ documentItem.completedDue }} <br v-if="documentItem.completedDate">
                          <span v-if="documentItem.completedDate && documentItem.status == 'Complete'"
                            :style="{ 'color': documentItem.completedDate <= documentItem.completedDue ? 'green' : 'red' }">{{
                              documentItem.completedDate }}</span>
                        </v-list-item-action-text>
                      </v-list-item>
                        </template>
                    <v-date-picker
                      v-model="documentItem.completedDate"
                      @change="updateCompletedDate()"
                      :min="documentItem.resubmitDate ? documentItem.resubmitDate :documentItem.submittedDate"  
                      no-title
                      scrollable
                      color="primary"
                    >
                    </v-date-picker>
                      </v-menu>
                     
                      <v-list-item id="document-notes" v-if="documentItem.comment">
                        <v-list-item-action>
                          <v-icon color="grey">description</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Notes</v-list-item-title>
                          <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                            {{ documentItem.comment }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item id="document-special-instruc" v-if="documentItem.comment">
                        <v-list-item-action>
                          <v-icon color="amber">star</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Special Instructions</v-list-item-title>
                          <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                            {{ documentItem.comment }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list dense v-if="item.onBehalfShipper" class="my-0 py-0">
                        <!-- <v-subheader>Shipper Detail Settings</v-subheader> -->
                        <v-select id="shipper-address" class="my-0 py-0" label="Shipper Document Address"
                          :disabled="shipment && shipment.documentationStatus == 'CLOSED'"
                          v-model="documentItem.shipperAddress" item-value="value" item-text="name"
                          :items="availableShipperAddresses" @change="updateSaveDocument('shipperAddress')" outlined dense></v-select>
                        <v-checkbox id="shipper-display" class="my-0 py-0"
                          :disabled="shipment && shipment.documentationStatus == 'CLOSED'"
                          label="Display on behalf shipper name"
                          v-model="documentItem.displayOnBehalfShipperName"  @change="updateSaveDocument('displayOnBehalfShipperName')"></v-checkbox>
                      </v-list>
                      <v-list dense class="my-0 py-0" v-if="item.onBehalfConsignee">
                        <!-- <v-subheader>Consignee Detail Settings</v-subheader> -->
                        <v-select class="my-0 py-0" id="consignee-address" label="Consignee Document Address"
                          v-model="documentItem.consigneeAddress"
                          :disabled="shipment && shipment.documentationStatus == 'CLOSED'" item-value="value"
                          item-text="name" :items="availableConsigneeAddresses" @change="updateSaveDocument('consigneeAddress')" outlined dense></v-select>
                        <v-checkbox id="consignee-display"
                          :disabled="shipment && shipment.documentationStatus == 'CLOSED'" class="my-0 py-0"
                          label="Display on behalf consignee name"
                          v-model="documentItem.displayOnBehalfConsigneeName" @change="updateSaveDocument('displayOnBehalfConsigneeName')"></v-checkbox>
                      </v-list>

                      <v-list dense subheader id="document-containers" class="mb-0 pb-0">
                        <v-list-item v-if="documentItem.quantityType == 'Shipment'">
                          <v-list-item-action>
                            <v-icon color="grey">directions_boat</v-icon>
                            </v-list-item-action> 
                            <v-list-item-content>
                              <v-list-item-title>
                                Shipment
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Quantity Type
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        <v-list-item style="height: 25px" v-else-if="documentItem.bookingContainer">
                          <v-list-item-action>
                            <v-icon color="grey">widgets</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Linked Container</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action-text>
                            {{
                              documentItem.bookingContainer.containerNo
                            }} {{
  documentItem.bookingContainer.ctoNo
}}
                          </v-list-item-action-text>
                        </v-list-item>
                        <v-divider v-if="linkedDocuments.length > 0" ></v-divider>
                        <v-subheader v-if="linkedDocuments.length > 0" style="font-size: 16px">Linked Documents
                        </v-subheader>
                        <v-list-item v-for="supporting in linkedDocuments" :key="supporting.id">
                          <v-list-item-action style="width: 30px" class="mx-0 px-0 pr-2">
                            <v-img style="width: 25px; height: 25px" contain :src="getDocumentIcon(supporting.name)" />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ supporting.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="supporting.reference">
                              {{ supporting.reference }} <span v-if="supporting.referenceConfidence">({{
                                supporting.referenceConfidence }}% Confidence)</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon @click="downloadFile(supporting)"
                                  :loading="docLoading == supporting.id"><v-icon>download</v-icon></v-btn>
                              </template>
                              <span style="font-size: 12px">Download</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                        <!-- <v-subheader v-if="documentItem.shipmentDocumentTypeSupportings.length > 0"
                          style="font-size: 16px">Suggested Documents
                        </v-subheader>
                        <v-list-item v-for="suggested in filterUploadedDocuments"
                          :key="suggested.id">
                          <v-list-item-action style="width: 30px" class="mx-0 px-0 pr-2">
                            <v-img style="width: 25px; height: 25px" contain :src="getDocumentIcon(suggested.name)"/>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ suggested.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-for="(tag, index) in matchingTags(suggested)" :key="index">
                            - {{ tag.name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item> -->
                        <v-subheader v-if="documentContainer.length > 0" style="font-size: 16px">Linked
                          Container(s)</v-subheader>

                        <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                          <v-list-item v-for="container in documentContainer" :key="container.id">
                            <v-list-item-content>
                              <v-list-item-title>{{
                                container.containerNo
                              }} - {{ container.ctoNo }}</v-list-item-title>
                              <v-list-item-subtitle>{{
                                container.sealNo
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    <v-divider></v-divider>
                      <v-subheader style="font-size: 16px;">
                      <v-icon color="grey" class="mr-2">history</v-icon>
                      Status Log </v-subheader>
                    <v-divider></v-divider>

                    <v-list dense class="my-0 py-0" id="document-status-log">
                      <v-progress-linear indeterminate color="primary" v-if="loadingDocumentLog"></v-progress-linear>
                      <v-list-item v-if="!loadingDocumentLog && documentLog.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No Status Logs</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="log in documentLog" :key="log.id">
                        <v-list-item-content>

                          <v-list-item-title>
                            <v-icon small class="mr-2" :color="getStatusColor(log.status)">{{ getStatusIcon(log.status)
                            }}</v-icon> {{ log.status }} <span v-if="log.actualDate"> at {{ log.actualDate }}</span>
                          </v-list-item-title>

                          <v-list-item-subtitle v-if="log.user" class="text-wrap">
                            <v-icon color="grey" small class="mr-2">person</v-icon> {{ log.user.firstname }} {{
                              log.user.surname }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else class="text-wrap">
                            <v-icon color="grey" small class="mr-2">code</v-icon> System Executed
                          </v-list-item-subtitle>

                          <v-list-item-subtitle v-if="log.shipmentDocumentTypeComment" class="text-wrap">
                            <v-icon color="grey" small class="mr-2">comment</v-icon> {{
                              log.shipmentDocumentTypeComment.comment }}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="text-wrap">
                            <v-icon color="grey" small class="mr-2">schedule</v-icon>{{ formatDate(log.createdAt) + ' (Executed Date)'}}
                          </v-list-item-subtitle>

                          <!-- <v-list-item-subtitle class="text-wrap" v-if="log.actualDate">
                            <v-icon color="grey" small class="mr-2">done</v-icon>{{ log.actualDate + ' ' + `(${log.status}
                            Date)`}}
                          </v-list-item-subtitle> -->

                        </v-list-item-content>
                        <!-- <v-list-item-action v-if="log.status == 'Resubmitted'">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" :disabled="shipment && shipment.documentationStatus == 'CLOSED'"
                                @click="log.isBillable = !log.isBillable, updateDocumentLogItem(log)"
                                :loading="savingDocumentLogItem.includes(log.id)">
                                <v-icon :color="log.isBillable ? 'green' : 'primaryText'">paid</v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ log.isBillable ? 'This is an additional bill' : 'This is not an additional bill' }}
                            </span>
                          </v-tooltip>
                        </v-list-item-action> -->
                      </v-list-item>
                    </v-list>
                    </v-list>
                    

                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-divider></v-divider>

                    <v-subheader style="font-size: 16px;">
                      <v-icon color="grey" class="mr-2">error</v-icon>
                      Service Failures<v-spacer></v-spacer> <v-btn icon color="primary" @click="addServiceFailure(false)"
                        :disabled="shipment && shipment.documentationStatus == 'CLOSED'"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                    <v-divider></v-divider>

                    <v-list dense class="my-0 py-0" id="document-service-failures">
                      <v-progress-linear indeterminate color="primary" v-if="loadingServiceFailures"></v-progress-linear>
                      <v-list-item v-if="!loadingServiceFailures && serviceFailures.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No Service Failures</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="failure in serviceFailures" :key="failure.id"
                        :disabled="shipment && shipment.documentationStatus == 'CLOSED'"
                        @click="editServiceFailure(failure)">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ failure.serviceFailureCategory.name }} <span v-if="failure.serviceFailureSubcategory"> - {{
                              failure.serviceFailureSubcategory.name }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="failure.createdBy" class="text-wrap">
                            <v-icon color="grey" small class="mr-2">person</v-icon> {{ failure.createdBy.firstname }} {{
                              failure.createdBy.surname }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="failure.responsibleParty || failure.responsibleOrganisation"
                            class="text-wrap">
                            <v-icon color="grey" small class="mr-2">business</v-icon> <span
                              v-if="failure.responsibleOrganisation">{{ failure.responsibleOrganisation.name }}</span>
                            <span v-else>Other: {{ failure.responsibleParty }}</span>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="failure.isBillable">
                            <v-icon :color="'green'" small class="mr-2">paid</v-icon> Billable
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="text-wrap">
                            <v-icon color="grey" small class="mr-2">event</v-icon>{{ formatDate(failure.createdAt) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="failure.comment" class="text-wrap">
                            <v-icon color="grey" small class="mr-2">chat</v-icon> {{ failure.comment }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>


                    <!-- <v-subheader style="font-size: 16px; color:#41AA3F">Document Log 
                      <v-btn id="info-guide" @click="addComment" style="color:#41AA3F;background-color:none">
                       <v-icon small>add_comment</v-icon>
                    </v-btn>
                    </v-subheader> -->
                   
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 16px;">
                      <v-icon color="grey" class="mr-2">add_comment</v-icon>
                      Comments <v-spacer></v-spacer> <v-btn icon color="primary" @click="addComment()"
                        :disabled="shipment && shipment.documentationStatus == 'CLOSED'"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                    <v-divider></v-divider>

                    <v-list dense class="my-0 py-0" id="document-status-log">
                      <v-progress-linear indeterminate color="primary" v-if="commentsLoading"></v-progress-linear>
                      <v-list-item v-if="!commentsLoading && comments.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No Comments</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="log in comments" :key="log.id">
                        <v-list-item-action class="mx-0 px-0 pr-2 text-center">
                          <v-avatar :color="log.createdBy.avatar ? 'white':'secondary'" size="50">
                            <v-img v-if="log.createdBy.avatar" :src="log.createdBy.avatar" contain></v-img>
                            <h3 v-else>{{ log.createdAt.firstname.charAt(0) }}</h3>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">

                          <v-list-item-title class="text-wrap" style="font-size: 12px">
                            {{ log.comment }}
                          </v-list-item-title>

                          <v-list-item-subtitle style="font-size: 11px" class="my-0 py-0">
                            <v-icon color="grey" small class="mr-2">person</v-icon> {{ log.createdBy.firstname }} {{
                              log.createdBy.surname }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 11px" class="my-0 py-0">
                            <v-icon color="grey" small class="mr-2">code</v-icon> {{log.commentType}}
                          </v-list-item-subtitle>

                          <v-list-item-subtitle style="font-size: 11px" class="my-0 py-0">
                            <v-icon color="grey" small class="mr-2">schedule</v-icon>{{ formatDate(log.createdAt)}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 16px;">
                      <v-icon color="grey" class="mr-2">picture_as_pdf</v-icon>
                      All Linked Documents<v-spacer></v-spacer></v-subheader>
                    <v-divider></v-divider>
                    <v-list dense class="my-0 py-0" id="document-status-log">
                      <v-progress-linear indeterminate color="primary" v-if="documentsLoading"></v-progress-linear>
                      <v-list-item v-if="!documentsLoading && allDocuments.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="color: grey">No Documents</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="taskDoc of allDocuments" :key="taskDoc.id">
                              <v-list-item-action v-if="taskDoc.file" class="ml-0 pl-0" style="width: 15px">
                                <v-img v-if="getDocumentIcon(taskDoc.file)" style="height: 25px; width: 25px"
                                  :src="getDocumentIcon(taskDoc.file)">
                                </v-img>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ taskDoc.name }} <v-spacer></v-spacer> 
                                  <!-- <v-btn icon @click="logItem(doc)"><v-icon>visibility</v-icon></v-btn> -->
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="taskDoc.file">
                                  {{ formatDate(taskDoc.createdAt) }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  Task: #{{taskDoc.taskId}}
                                </v-list-item-subtitle>
                              </v-list-item-content>
  
                              <v-list-item-action :key="documentKey">
                                <v-row :key="documentKey">
                                  <v-btn :key="documentKey" :loading="taskDoc.id && previewLoading == taskDoc.id" icon
                                    v-if="taskDoc.file && (taskDoc.file.toLowerCase()).includes('.pdf')" small
                                    @click="previewDocument(taskDoc)"><v-icon small>visibility</v-icon></v-btn>
                                  <!-- <v-btn icon v-if="doc.file && doc.code" small
                                            @click="attachDocument(doc)"><v-icon small>edit</v-icon></v-btn> -->
                                  <v-btn v-if="taskDoc.file" icon :loading="taskDoc.id && docLoading == taskDoc.id"
                                    @click="downloadDocument(taskDoc)" small><v-icon small>download</v-icon></v-btn>
                                  <v-btn v-else small icon @click="attachDocument(taskDoc)"><v-icon
                                      small>add</v-icon></v-btn>
                                  <v-btn color="red"
                                    :disabled="shipment && shipment.documentationStatus == 'CLOSED'" small icon
                                    @click="deleteAttachment(taskDoc, task)"><v-icon small>delete</v-icon></v-btn>
                                </v-row>
                              </v-list-item-action>
                            </v-list-item>
                    </v-list>



                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-list dense subheader>
                      <v-subheader style="font-size: 16px">Tasks</v-subheader>
                      <v-card id="document-tasks" outlined tile v-for="task in documentTasks(documentItem)" :key="task.id"
                        class="my-0 py-0">
                        <v-toolbar dense flat style="border-bottom: 1px solid grey">
                          <v-toolbar-title style="font-size: 14px">
                            <v-chip class="mr-2" :color="getTaskStatus(task).color" outlined small><v-icon small left>{{
                              getTaskStatus(task).icon
                            }}</v-icon>
                              {{ task.status }}</v-chip>
                            {{ task.title }}
                          </v-toolbar-title>
                          <v-spacer></v-spacer>
                          <span style="font-size: 12px" v-if="documentsRequired(task)"><v-icon small
                              color="orange">warning</v-icon>
                            Documents
                            Required</span>
                          <v-btn v-if="(task.taskTypeId == 5 && task.status != 'Not started') || task.taskTypeId != 5" icon @click="viewTask(task)"><v-icon>launch</v-icon></v-btn>
                        </v-toolbar>
                        <v-card-text class="my-0 pt-0 mx-0">
                          <v-list dense class="ma-0 pa-0">
                            <v-list-item v-if="task.reference">
                              <v-list-item-action>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" color="secondary">tag</v-icon>
                                  </template>
                                  <span>Task Reference Result</span>
                                </v-tooltip>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ task.reference }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Reference
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="task.inspectionCode">
                              <v-list-item-action>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn icon small v-on="on">
                                      <v-icon :color="getInspectionColor(task.inspectionStatus)">rule</v-icon>
                                    </v-btn>
                                  </template>
                                  <span v-if="!task.inspectionStatus">Inspection Required</span>
                                  <span v-else>Inspection status: {{ task.inspectionStatus }}</span>
                                </v-tooltip>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ task.inspectionStatus ?? 'Inspection Required' }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  Inspection Status
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="task.childTasks && task.childTasks.length > 0" @click="viewSubtasks(task)">
                              <v-list-item-action>
                                <v-icon color="grey">
                                  task
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Subtasks Complete
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-chip :color="task.childTasks.filter(
                                  (x) => x.status == 'Complete'
                                ).length == task.childTasks.length
                                  ? 'green'
                                  : 'orange'" small outlined>
                                  <b :style="{
                                    'font-size': '14px'
                                  }">{{
  task.childTasks.filter(
    (x) => x.status == "Complete"
  ).length
}}/{{ task.childTasks.length }}
                                  </b>
                                </v-chip>

                              </v-list-item-action>
                            </v-list-item>
                            <v-expand-transition :key="subtaskKey">
                              <v-list class="pl-3" v-if="displayedSubtasks.includes(task.id)">

                                <v-list-item v-for="child in task.childTasks" :key="child.id">
                                  <v-list-item-action>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon class="mr-2" v-on="on" :color="getTaskStatus(child).color">{{
                                          getTaskStatus(child).icon
                                        }}</v-icon>
                                      </template>
                                      <span>{{ getTaskStatus(child).name }}</span>
                                    </v-tooltip>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ child.title }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>

                            </v-expand-transition>

                            <v-row>
                              <v-col cols="12" sm="6" class="mx-0 px-0">
                                <v-divider></v-divider>

                                <v-subheader style="font-size: 16px">
                                  <!-- <v-icon class="mr-2"
                                      color="grey">add_task</v-icon> -->
                                  Actions</v-subheader>
                                <v-divider></v-divider>

                                <v-progress-linear indeterminate color="primary"
                                  v-if="loadingTaskActions.includes(task.id)"></v-progress-linear>
                                <v-list dense :key="taskActionKey" class="mx-0 px-0">
                                  <v-list-item v-if="actionRequired(task)" @click="completeForm(task)" :disabled="loadingTaskActions.includes(task.id)">
                                    <v-list-item-action>
                                      <v-icon color="orange">warning</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Complete Required Form
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-progress-circular size="24" v-if="loadingForm == task.id"
                                        indeterminate color="primary"></v-progress-circular>
                                        </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item v-else-if="actionRequiredData(task)" @click="completeForm(task)"  :disabled="loadingTaskActions.includes(task.id)">
                                    <v-list-item-action>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-icon v-bind="attrs" v-on="on"
                                            v-if="actionRequiredData(task).status == 'Pending'"
                                            color="orange">schedule</v-icon>
                                          <v-icon v-bind="attrs" v-on="on" v-else-if="actionRequiredData(task).status == 'In Progress'
                                            " color="blue">pending</v-icon>
                                          <v-icon v-bind="attrs" v-on="on"
                                            v-else-if="actionRequiredData(task).status == 'Complete'"
                                            color="green">task_alt</v-icon>
                                        </template>
                                        <span style="font-size: 12px">{{
                                          actionRequiredData(task).status
                                        }}</span>
                                      </v-tooltip>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Form: {{ actionRequiredData(task).form.name }}
                                      </v-list-item-title>
                                      <!-- <v-list-item-subtitle>
                                    {{ actionRequiredData(task).locationDocument.name }}
                                  </v-list-item-subtitle> -->
                                      <v-list-item-subtitle style="font-size: 12px"
                                        v-if="actionRequiredData(task).user && actionRequiredData(task).status == 'Complete'">
                                        Submitted by {{ actionRequiredData(task).user.firstname }}
                                        {{ actionRequiredData(task).user.surname }}
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle style="font-size: 12px">
                                        {{ formatDate(actionRequiredData(task).updatedAt) }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-progress-circular size="24" v-if="loadingForm == task.id"
                                        indeterminate color="primary"></v-progress-circular>
                                        </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item :disabled="shipment && shipment.documentationStatus == 'CLOSED'"
                                    v-if="documentsRequired(task)" @click="addAttachment(task)">
                                    <v-list-item-action>
                                      <v-icon color="orange">warning</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Upload Required Documents
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item :disabled="(shipment && shipment.documentationStatus == 'CLOSED') || loadingTaskActions.includes(task.id)"
                                    v-if="task.taskTypeId != 5 && (!actionRequired(task)) && !documentsRequired(task) && task.status == 'Not started'"
                                    @click="checkParentDoc(task)">
                                    <v-list-item-action>
                                      <v-icon color="blue">play_circle_filled</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Start Task
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item :disabled="(shipment && shipment.documentationStatus == 'CLOSED') || loadingTaskActions.includes(task.id)"
                                    v-if="task.taskTypeId == 5 && (!actionRequired(task)) && !documentsRequired(task) && task.status == 'Not started'"
                                    @click="checkParentDoc(task)">
                                    <v-list-item-action>
                                      <v-icon color="blue">visibility</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Preview Form
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <div
                                    v-if="!actionRequired(task) && !documentsRequired(task) && task.status != 'Not started'">
                                    <v-list-item
                                      :disabled="(shipment && shipment.documentationStatus == 'CLOSED') || !action.statuses.includes(task.status) || loadingAction == (action.code + '_' + task.id)"
                                      @click="action.confirmRequired? confirmAction(action, task) :executeAction(action, task)" v-for="action in availableActions(task)"
                                      :key="action.code">
                                      <v-list-item-action>
                                        <v-icon :color="action.color">{{
                                          action.icon
                                        }}</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ action.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                          {{ action.description }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-progress-circular size="24" v-show="loadingAction && loadingAction == (action.code + '_' + task.id)
                                          " indeterminate color="primary"></v-progress-circular>
                                      </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="!actionRequiredData(task) && !documentsRequired(task) && availableActions(task).length == 0">
                                      <v-list-item-content class="text-center">
                                        <span style="color: grey">No actions available</span>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </div>
                                </v-list>
                              </v-col>
                              <v-col cols="12" sm="6" class="mx-0 px-0">
                                <v-divider></v-divider>
                                <v-subheader style="font-size: 16px">
                                  <!-- <v-icon class="mr-2"
                                      color="grey">attachment</v-icon> -->
                                  Attachments
                                  <v-btn :key="attachmentKey" :disabled="shipment.documentationStatus == 'CLOSED'"
                                    @click="addAttachment(task)" icon v-if="task.taskAttachments.filter((x) => !x.file).length > 0
                                      " color="primary"><v-icon>add_circle_outline</v-icon></v-btn>
                                </v-subheader>
                                <v-divider></v-divider>
                                <v-progress-linear color="primary" indeterminate
                                  v-if="loadingAttachments.includes(task.id)"></v-progress-linear>
                                <v-list dense :key="attachmentKey" style="max-height: 45vh; overflow-y: auto;"
                                  class="mx-0 px-0">
                                  <v-list-item v-for="doc in task.taskAttachments.filter(
                                    (x) => x.file
                                  )" :key="doc.id">
                                    <v-list-item-action class="ml-0 pl-0" style="width: 15px">
                                      <v-img v-if="getDocumentIcon(doc.file)" style="height: 25px; width: 25px"
                                        :src="getDocumentIcon(doc.file)">
                                      </v-img>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ doc.name }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle v-if="doc.file">
                                        {{ formatDate(doc.createdAt) }}
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle v-else>
                                        <i v-if="doc.required">Required</i>
                                        <i v-else>Not Required</i>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action :key="documentKey">
                                      <v-row :key="documentKey">
                                        <v-btn :key="documentKey" :loading="doc.id && previewLoading == doc.id" icon
                                          v-if="doc.file && (doc.file.toLowerCase()).includes('.pdf')" small
                                          @click="previewDocument(doc)"><v-icon small>visibility</v-icon></v-btn>
                                        <!-- <v-btn icon v-if="doc.file && doc.code" small
                                                  @click="attachDocument(doc)"><v-icon small>edit</v-icon></v-btn> -->
                                        <v-btn v-if="doc.file" icon :loading="doc.id && docLoading == doc.id"
                                          @click="downloadDocument(doc)" small><v-icon small>download</v-icon></v-btn>
                                        <v-btn v-else small icon @click="attachDocument(doc)"><v-icon
                                            small>add</v-icon></v-btn>
                                        <v-btn color="red"
                                          :disabled="shipment && shipment.documentationStatus == 'CLOSED'" small icon
                                          @click="deleteAttachment(doc, task)"><v-icon small>delete</v-icon></v-btn>
                                      </v-row>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item v-if="task.taskAttachments.filter((x) => x.file).length == 0
                                    ">
                                    <v-list-item-content class="text-center">
                                      <span style="color: grey">No attachments.</span>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                            </v-row>
                          </v-list>
                        </v-card-text>
                      </v-card>

                      <v-list-item id="document-tasks" v-if="documentTasks(documentItem).length == 0">
                        <v-list-item-content class="text-center">
                          <span style="font-size: 14px; color: grey">No tasks for this document.</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="media">
            <v-card flat style="border-radius: 0px" id="document-media">
              <v-card-text>
                <v-row>
                  <v-col id="document-media" cols="12" sm="6" md="4" v-for="note in documentMedia(documentItem)"
                    :key="note.id">
                    <v-hover v-slot:default="{ hover }">
                      <v-card style="border-radius: 20px" outlined class="pa-0 ma-0">
                        <v-expand-transition>
                          <v-card v-if="hover"
                            class="d-flex transition-fast-in-fast-out primary v-card--reveal display-3 white--text" style="height: 100%;
                                              background-color: var(
                                                --v-primary-base
                                              ) !important;
                                            ">
                            <v-card-text class="py-0 my-0">
                              <v-row justify="space-around">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="downloadDocument(note)"
                                      :loading="docLoading == note.id"><v-icon>download</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Download</span>
                                </v-tooltip>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="deleteDocument(note)"
                                      :loading="note.loading" color="red"><v-icon>delete</v-icon></v-btn>
                                  </template>
                                  <span style="font-size: 12px">Delete</span>
                                </v-tooltip>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-expand-transition>
                        <v-card-text class="my-0 mx-0 px-0">
                          <v-row justify="center">
                            <v-col cols="12" class="my-0 py-0">
                              <v-list-item class="my-0 py-0">
                                <v-list-item-action class="ml-0 pl-0" style="width: 20px">
                                  <v-img class="mr-2" style="height: 30px; width: 30px" :src="getDocumentIcon(note.name)">
                                  </v-img>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title class="text-left" style="font-size: 14px">
                                    {{ note.name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle style="font-size: 12px">
                                    {{ formatDate(note.createdAt) }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col id="document-media" cols="12" class="text-center" v-if="documentMedia(documentItem).length == 0">
                    <span style="color: grey">No documents loaded</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>


          </v-tab-item>
        </v-tabs-items>

      </v-card-text>
    </v-card>
    <v-dialog v-model="submittedDateModal" persistent width="290px">
      <v-date-picker v-model="tempSubmitDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="submittedDateModal = false && tempSubmitDate == null">
          Cancel
        </v-btn>
        <v-btn text color="primary" :loading="savingDate" @click="updateSubmissionDate">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog v-model="resubmitDateModal" persistent width="290px">
      <v-date-picker v-model="tempResubmitDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="resubmitDateModal = false && tempResubmitDate == null">
          Cancel
        </v-btn>
        <v-btn text color="primary" :loading="savingDate" @click="updateResubmitDate">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog v-model="completedDateModal" persistent width="290px">
      <v-date-picker v-model="tempCompletedDate" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="completedDateModal = false && tempCompletedDate == null">
          Cancel
        </v-btn>
        <v-btn text color="primary" :loading="savingDate" @click="updateCompletedDate">
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-dialog v-model="taskModal" width="1200px" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <TaskModal :task="task" :shipment="shipment" :bookings="bookings" @updateDocument="updateDocument"
      :parentDoc="documentItem"
        :actions="actions" :documents="uploads" :documentTypes="documentTypes" @viewSubtask="viewSubtask" :taskKey="taskKey"
        @close="(taskModal = false), (task = {}), refreshDocumentData()" :documentUpdateKey="documentUpdateKey"
        @update="update, refreshDocumentData()" :statusKey="taskUpdateKey"
        @refreshAttachments="getTaskAttachments, refreshDocumentData()" :fileNumber="fileNumber" />
    </v-dialog>

    <v-dialog v-model="formPreview" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <FormModal v-if="form.form" :form="form.form" :key="formKey" @close="formPreview = false" @submit="submitForm" />
    </v-dialog>

    <v-dialog v-model="previewModal" :fullscreen="$vuetify.breakpoint.mobile || fullscreen" width="1200px">
      <v-card flat>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            {{ previewItem.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
              
          <v-btn icon color="redPop" @click="(previewModal = false), (previewItem = {})"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text :style="{
          'max-height': fullscreen ? '88vh' : '75vh',
          'overflow-y': 'auto',
          'background-color': 'var(--v-modal-base) !important',
        }">
          <v-row justify="center">
            <v-col cols="12" md="9" lg="8" xl="6" class="text-center">
              <div ref="pdfBox">
                <pdf style="display: none;" :src="previewItem.fullFile" @num-pages="pageCount = new Array($event)" />
                <pdf v-for="(page, index) in pageCount" :key="index" :src="previewItem.fullFile" :page="index + 1"
                  class="contract-container" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addAttachmentModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>
          Add Attachment
          <v-spacer></v-spacer>
          <v-btn icon color="blue" @click="getTasks();" :loading="refreshTasks"><v-icon>refresh</v-icon></v-btn>
          <v-btn icon color="primary" @click="saveAttachment" :disabled="!attachmentItem.file || !attachmentItem.taskAttachment" :loading="savingDocumentAttachment"><v-icon>save</v-icon></v-btn>
          <v-btn icon color="redPop" @click="(addAttachmentModal = false), (attachmentItem = {})"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-autocomplete label="Select Document Type" item-text="name" item-value="id"
            :items="availableAttachmentDocuments()" v-model="attachmentItem.taskAttachment" outlined dense
            return-object></v-autocomplete>
       <div style="display: flex;flex-direction: row;justify-content: space-around;cursor: pointer;align-items: baseline">

       
        <div style="margin: 5px;">
        <v-btn text @click="addFiles()" :loading="uploadingDocument"> 
        <v-icon>upload_file</v-icon>  
        Upload Document                    
        </v-btn>     
        </div>
       

     <div style="margin: 5px;">
      <v-autocomplete label="Select Uploaded Document" item-text="name" item-value="key" :items="updatedTasks"
        v-model="attachmentItem.file" outlined dense>
      </v-autocomplete>
     </div>

       </div>
          <v-checkbox v-if="attachmentItem.taskAttachment && attachmentItem.taskAttachment.attestOption"
            label="Attest Document" v-model="attachmentItem.taskAttachment.attest"></v-checkbox>
           
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="commentModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent"><v-toolbar-title>Comment</v-toolbar-title> <v-spacer></v-spacer>
          <v-btn color="primary" icon :loading="savingComment" @click="saveComment"
            :disabled="!commentItem.comment"><v-icon>save</v-icon></v-btn>
          <v-btn color="redPop" text @click="commentModal = false, commentItem = {}"><v-icon>close</v-icon></v-btn></v-toolbar>
        <v-card-text>

          <v-autocomplete label="Query" item-text="name" item-value="id" v-model="commentItem.commentType" outlined dense
            :value="'Query'" return-object disabled v-if="commentTypes == 'Query'"></v-autocomplete>

          <v-autocomplete label="Select Comment Type" item-text="name" item-value="id" :items="commentTypes"
            v-model="commentItem.commentType" outlined dense return-object v-else></v-autocomplete>
          <v-textarea v-model="commentItem.comment" label="Comment" outlined rows="3"></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="commentInspectionModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent"><v-toolbar-title>Comment</v-toolbar-title> <v-spacer></v-spacer>
          <!-- <v-btn color="primary" icon :loading="savingComment" @click="saveComment"><v-icon>save</v-icon></v-btn> -->
          <v-btn color="redPop" text @click="commentInspectionModal = false, commentItem = {}"><v-icon>close</v-icon></v-btn></v-toolbar>
        <v-card-text>

          <v-text-field label="Comment Type" v-model="commentItem.commentType" outlined dense disabled></v-text-field>

          <v-textarea v-model="commentItem.comment" label="Comment" outlined rows="3"
            disabled="commentItem.comment"></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="serviceFailureModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>

        <v-toolbar flat color="transparent">
          <v-toolbar-title>Service Failure</v-toolbar-title> 
          <v-spacer></v-spacer>
          <v-btn color="primary" text :loading="savingServiceFailure"
            :disabled="!serviceFailureItem.serviceFailureCategoryId || (!serviceFailureItem.responsibleOrganisationId && !serviceFailureItem.responsibleParty) || !serviceFailureItem.comment"
            @click="saveServiceFailure">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon color="redPop" v-if="serviceFailureItem &&!serviceFailureItem.blockClose" text @click="serviceFailureModal = false, serviceFailureItem = {}"><v-icon>close</v-icon></v-btn>
        </v-toolbar> 
        <v-card-text>
          <v-autocomplete label="Category*" :items="serviceFailureCategories"
            v-model="serviceFailureItem.serviceFailureCategoryId"
            @change="subcategoryKey++, serviceFailureItem.serviceFailureSubcategoryId = null" outlined dense clearable
            item-text="name" item-value="id"></v-autocomplete>
          <v-autocomplete :key="subcategoryKey" label="Subcategory"
            v-if="serviceFailureItem.serviceFailureCategoryId && serviceFailureSubcategories(serviceFailureItem.serviceFailureCategoryId).length > 0"
            :items="serviceFailureSubcategories(serviceFailureItem.serviceFailureCategoryId)"
            v-model="serviceFailureItem.serviceFailureSubcategoryId" outlined dense clearable item-text="name"
            item-value="id"></v-autocomplete>
          <v-autocomplete :items="availableParties" v-model="serviceFailureItem.responsibleOrganisationId"
            label="Responsible Party" outlined dense clearable item-text="name" item-value="value" hide-details></v-autocomplete>
          <v-text-field v-if="!serviceFailureItem.responsibleOrganisationId" v-model="serviceFailureItem.responsibleParty"
            outlined dense clearable label="Specify Party" hide-details></v-text-field>
          <v-switch v-model="serviceFailureItem.isBillable" label="Billable" dense hide-details class="mb-2"></v-switch>
          <v-textarea outlined rows="8" v-model="serviceFailureItem.comment" placeholder="Description"></v-textarea>
          <v-row class="mt-2" justify="center">
            <v-btn @click="deleteServiceFailure()" :loading="deletingServiceFailure"
              v-if="serviceFailureItem.id && serviceFailureItem.organisationId == $store.state.currentOrg.id" color="red"
              style="text-transform: none"><v-icon>delete</v-icon> Delete</v-btn>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="promptSaveModal" persistent width="500px">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>Save Changes</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
          <p style="font-size: 16px">
            Would you like to save your changes before closing?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn color="red" class="mx-2" text small
              @click="closeModal(true)">No</v-btn>
            <v-btn color="success" class="mx-2" text small @click="saveDocument(true)">Yes</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <file-upload class="my-0 py-0" post-action="" :multiple="true" :drop="true" :drop-directory="true" v-model="fileList"
    ref="uploader"></file-upload>
    <ShippingInstructionDialog ref="shippingInstructionDialog" @action="handleFormDocumentIntegrationTaskSubmit" />
  </div>
</template>
<script>
import dateFormat from "dateformat";
import FormModal from "@/components/SystemComponents/FormModal.vue";
import pdf from "vue-pdf";
import TaskModal from "../SystemComponents/TaskModal.vue";
import ShippingInstructionDialog from "@/components/Integrations/ShippingInstructions/ShippingInstructionDialog.vue";
import { SI_INTEGRATION_CODES, TASK_TYPES, INTEGRATION_CODES, SI_FORM_ACTION_TYPES, TASK_STATUS, SI_TEST_USERS_IDS } from "@/utils/constants";
import FileUpload from "vue-upload-component";

import axios from "axios";

export default {
  props: [
    "documentItem",
    "actions",
    "bookings",
    "documents",
    "item",
    "tasks",
    "taskUpdateKey",
    "documentUpdateKey",
    "shipment",
    "uploads",
    "events",
    "documentTypes",
    "relationships",
    "fileNumber",
    "openKey",
    "userScope",
    "taskKey"
  ],
  components: {
    FileUpload,
    FormModal,
    pdf,
    TaskModal,
    ShippingInstructionDialog
  },
  data: () => ({
    succesfullyUpdatedServiceFailure: false,
    tempSubmitDate: null,
    tempResubmitDate: null,
    tempCompletedDate: null,
    commentInspectionModal: false,
    savingComment: false,
    comments: [],
    commentsLoading: false,
    documentsLoading: false,
    allDocuments: [],
    fileList: [],
    commentItem: {},
    commentTypes: [
      // 'General',
      // 'Query',
      // 'Invoice'
    ],
    commentModal: false,
    todayDate: new Date(),
    actionKey: 3000,
    attachmentKey: 1000,
    attachmentItem: {},
    addAttachmentModal: false,
    completedDateModal: false,
    resubmitDateModal: false,
    deletingServiceFailure: false,
    displayedSubtasks: [],
    documentKey: 0,
    documentTab: "info",
    documentLog: [],
    docLoading: null,
    previewLoading: null,
    expandedProfile: false,
    expandedTask: null,
    form: {},
    formPreview: false,
    formKey: 4000,
    fullscreen: false,
    groupedHeader: "locationDocumentId",
    headers: [
      {
        text: "Status",
        value: "status",
        align: "center",
        width: "75px",
      },
      {
        text: "Integration",
        value: "integrationStatus",
        align: "center",
        width: "50px",
        groupable: false,
      },
      {
        text: "Name",
        value: "locationDocument.name",
        align: "left",
        groupable: false,
      },
      {
        text: "Reference",
        value: "reference",
        align: "left",
        groupable: false,
      },

      {
        text: "Submission Due",
        value: "submittedDue",
        align: "center",
        sortable: false,
      },
      {
        text: "Completion Due",
        value: "completedDue",
        align: "center",
        sortable: false,
      },
      // {
      //   text: "Tasks",
      //   value: "tasks",
      //   align: "center",
      //   sortable: false,
      // },

      {
        text: "Rank",
        value: "rank",
        align: "center",
        groupable: false,
        width: "50px",
      },
      {
        text: "Regulatory Body",
        value: "locationDocument.regulatoryBody.name",
        align: "left",
        groupable: false,
      },

      // {
      //   text: "Responsible Party",
      //   value: "responsiblePartyType",
      //   align: "center",
      //   width: "50px",
      // },
      {
        text: "Quantity",
        value: "quantity",
        align: "center",
        width: "50px",
      },
      {
        text: "Container(s)",
        value: "bookingContainer.containerNo",
        align: "center",
        sortable: false,
      },
      {
        text: "Courier",
        value: "courier",
        align: "center",
        width: "50px",
      }
    ],
    loadingAction: null,
    loadingAttachments: [],
    updatedTasks:[],
    loadingDocumentLog: false,
    loadingForm: false,
    loadingServiceFailures: false,
    loadingTaskActions: [],
    previewItem: {},
    previewModal: false,
    promptSave: false,
    promptSaveModal: false,
    pageCount: [],
    savingDate: false,
    savingDocument: false,
    savingDocumentAttachment: false,
    referenceTimeout: null,
    refreshTasks: false,
    uploadingDocument: false,
    savingDocumentLogItem: [],
    search: null,
    selectedTaskButton: [],
    serviceFailureCategories: [],
    serviceFailures: [],
    serviceFailureModal: false,
    serviceFailureItem: {},
    savingServiceFailure: false,
    showDetails: [],
    statusOptions: [
      "Not Required",
      "Query",
      "In Transit",
      "Cancelled",
      "Pending",
      "Submitted",
      "Complete",
    ],
    statusKey: 5000,
    statusStepper: 3,
    subcategoryKey: 7000,
    subtaskKey: 6000,
    submittedDateModal: false,
    taskModal: false,
    task: {},
    taskActionKey: 2000,
    checkStartTask: false,
    restartTask: {}
  }),
  watch: {
    taskKey(val){
      if(val){
        if(this.task){
          let index = this.tasks.findIndex(x => x.id == this.task.id)
          this.task = this.tasks[index]
          console.log("Updating task from watch", this.task)
        }
      }
    },
    fileList: {
            immediate: true,
            handler(val) {
                if (val.length > 0) {
                    this.submitFiles();
                }
            },
        },
    openKey(val) {
      console.log(val)
      this.promptSave = false
      console.log(this.documentItem)
      this.getDocumentServiceFailures(this.documentItem)
      this.getDocumentLog(this.documentItem.id)
      this.getDocumentComments(this.documentItem.id)
      this.getAllDocuments(this.documentItem.id)
    },
    "item.courierParty": {
      immediate: true,
      handler(val) {
        if (!val) {
          let headerItem = this.headers.findIndex((x) => x.value == "courier");
          this.headers[headerItem].text = '"Copy To" Document';
        } else {
          let headerItem = this.headers.findIndex((x) => x.value == "courier");
          this.headers[headerItem].text = "Courier Document";
        }
        // this.documentUpdateKey++;
      },
    },
    // "documentItem.id": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       this.getDocumentServiceFailures(this.documentItem)
    //       this.getDocumentLog(this.documentItem.id)
    //       // this.actionOptions();

    //     }
    //   },
    // }
  },
  created() {
    this.getServiceFailureCategories(),
    this.getTasks();
    // this.actionOptions()
  },
  computed: {
    // slicedFileNumber(){
    //   if(this.fileNumber){
    //     return this.fileNumber.slice(-6)
    //   }
    // },

    availableParties() {
      let result = []
      let parties = ['shipper', 'onBehalfShipper', 'forwarder', 'onBehalfForwarder', 'consignee', 'onBehalfConsignee', 'buyer', 'onBehalfBuyer', 'firstNotify', 'secondNotify', 'courierParty']
      for (let i = 0; i < parties.length; i++) {
        if (this.item[parties[i]]) {
          let findExisting = result.find(x => x.value == this.item[parties[i] + 'Id'])
          if (!findExisting) {
            result.push({
              name: this.item[parties[i]].name,
              value: this.item[parties[i] + 'Id']
            })
          }
        }
      }
      if (this.relationships && this.relationships.length > 0) {
        for (let i = 0; i < this.relationships.length; i++) {
          let findExisting = result.find(x => x.value == this.relationships[i].customerId)
          if (!findExisting) {
            result.push({
              name: this.relationships[i].customer.name,
              value: this.relationships[i].customerId
            })
          }
        }
      }
      result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      result.push({ name: 'Other', value: null })
      return result

    },
    availableConsigneeAddresses() {
      let result = [];
      if (this.item.consignee) {
        result.push({
          name: this.item.consignee.name,
          value: "consignee",
        });
      }
      if (this.item.onBehalfConsignee) {
        result.push({
          name: this.item.onBehalfConsignee.name,
          value: "onBehalfConsignee",
        });
      }
      return result;
    },
    availableShipperAddresses() {
      let result = [];
      if (this.item.shipper) {
        result.push({
          name: this.item.shipper.name,
          value: "shipper",
        });
      }
      if (this.item.onBehalfShipper) {
        result.push({
          name: this.item.onBehalfShipper.name,
          value: "onBehalfShipper",
        });
      }
      return result;
    },
    availableStatuses() {
      let options = this.statusOptions.filter((x) => x != "Not Required");
      return options;
    },

    documentContainer() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let containers = this.documentItem.shipmentDocumentTypeContainers.map(
          (x) => x.bookingContainerId
        );
        result = this.bookings.filter((x) => containers.includes(x.id));
      }
      return result;
    },
    documentTypeDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let linkedDocuments =
          this.documentItem.shipmentDocumentTypeDocuments.map(
            (x) => x.shipmentDocumentId
          );
        result = this.uploads.filter((x) => linkedDocuments.includes(x.id));
      }
      return result;
    },
    documentTypeSupportingDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let variationDocuments = [].concat.apply(
          [],
          this.documentItem.variations.map(
            (x) => x.shipmentDocumentTypeSupportings
          )
        );
        variationDocuments = variationDocuments.map(
          (x) => x.shipmentDocumentId
        );
        result = this.uploads.filter((x) => variationDocuments.includes(x.id));
      }
      return result;
    },
    filterUploadedDocuments() {
      let result = []
      if (this.documentItem && this.documentItem.id) {
        console.log(this.uploads)
        result = this.uploads.filter(x => x.shipmentDocumentClassifications.map(y => y.supportingDocumentId).some(y => this.documentItem.shipmentDocumentTypeSupportings.map(z => z.supportingDocumentId).includes(y)))
      }
      return result
    },
    linkedDocuments() {
      let result = []
      if (this.documentItem && this.documentItem.shipmentDocumentTypeSupportings.length > 0) {
        let linked = this.documentItem.shipmentDocumentTypeSupportings.filter(x => x.shipmentDocumentId)
        result = this.uploads.filter(x => linked.map(y => y.shipmentDocumentId).includes(x.id))
        result = result.map(x => ({
          ...x,
          reference: linked.find(y => y.shipmentDocumentId == x.id).reference,
          referenceConfidence: linked.find(y => y.shipmentDocumentId == x.id).referenceConfidence,
        }))
      }
      // console.log(result)
      return result
    },
    taskDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let taskAttachments = this.documentTasks(this.documentItem);
        result = [].concat.apply(
          [],
          taskAttachments.map((x) => x.taskAttachments)
        );
      }

      result = [...result, ...this.supportingDocuments];
      return result;
    },

    supportingDocuments() {
      let result = [];
      if (this.documentItem && this.documentItem.id) {
        let supportingDocuments =
          this.documentItem.shipmentDocumentTypeSupportings
            .filter((x) => x.shipmentDocumentId)
            .map((x) => x.shipmentDocumentId);
        let variations = this.documentItem.variations;
        let variationSupportingDocuments = [].concat.apply(
          [],
          variations.map((x) =>
            x.shipmentDocumentTypeSupportings
              .filter((y) => y.shipmentDocumentId)
              .map((y) => y.shipmentDocumentId)
          )
        );
        supportingDocuments = [
          ...supportingDocuments,
          ...variationSupportingDocuments,
        ];
        result = this.uploads.filter((x) => supportingDocuments.includes(x.id));
      }

      return result;
    },
    canViewSITask() {
      const isSystemAdmin = [1, 2].includes(this.$store.state.user.tagId);
      if(isSystemAdmin) return true;

      const hardcodedUserIds = SI_TEST_USERS_IDS; //TODO - Remove, only for testing
      if(hardcodedUserIds.includes(this.$store.state.user.id)) return true

      return false;
    },
  },
  methods: {
    
    // async updateCurrentTask(){
    //   let obj={
    //     id:122803,
    //     status:'Approved',
    //     parentShipmentDocumentId: 139668
    //   }
    //   let result = await this.$API.updateTask(obj)
    //   console.log(result)
    // },
    async updateIsBillable() {
      this.documentItem.isBillable = !this.documentItem.isBillable
      await this.$API.updateShipmentDocumentType({
        id: this.documentItem.id,
        isBillable: this.documentItem.isBillable
      })
      this.$emit('updateDocument', this.documentItem)
      this.$message.success('Successfully updated!')
    },
    // todayDate(date){
    //   let today = new Date()
    //   today = today.toISOString().split('T')[0]
    //   return date === today
    // },
    actionOptions() {
      let submitDate = this.documentItem.submittedDate ?? null
      let completeDate = this.documentItem.completedDate ?? null
      if (completeDate != null) {
        this.statusOptions = [
          "Not Required",
          'Query',
          "In Transit",
          "Cancelled"
        ]
      }
      else if (submitDate != null) {
        this.statusOptions = [
          "Not Required",
          'Query',
          "In Transit",
          "Cancelled"
        ]
      }
      else {
        this.statusOptions = [
          "Not Required",
          'Query',
          "In Transit",
          "Cancelled",

        ]
      }
    },
    actionRequired(task) {
      let result = false;
      if (this.documents && this.documents.length > 0) {
        let findDoc = this.documents.find(
          (x) =>
            (x.id === task.shipmentDocumentTypeId ||
              x.id === task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            result = findAction ? findAction.status != "Complete" : true;
          }
        }
      }
      return result;
    },
    actionRequiredData(task) {
      let result = false;
      if (this.documents && this.documents.length > 0) {
        let findDoc = this.documents.find(
          (x) =>
            (x.id === task.shipmentDocumentTypeId ||
              x.id === task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            result = findAction
          }
        }
      }
      return result;
    },
    addAttachment(task) {
      this.attachmentItem = {
        task: task
      }
      this.addAttachmentModal = true
    },
    async addServiceFailure(blockClose = false) {
      this.serviceFailureItem = {
        responsibleOrganisationId: this.$store.state.currentOrg.id,
        isBillable: false,
        blockClose: blockClose
      }
      this.serviceFailureModal = true;

    },
    availableActions(task) {
      return task && task.taskActions ? task.taskActions.filter((x) => x.statuses.includes(task.status) && (!x.submissionType || x.submissionType.includes(task.submissionType))) : [];
    },
    availableAttachmentDocuments() {
      let result = []
      if (this.attachmentItem && this.attachmentItem.task) {
        result = this.attachmentItem.task.taskAttachments
          ? this.attachmentItem.task.taskAttachments.filter((x) => !x.file)
          : [];
      }

      return result
    },
    calculateProgress(item) {
      let options = [
        "Open",
        "In Progress",
        "Pending Draft Approval",
        "Draft Approved",
        "Submitted",
        "Pending Attestation",
        "Attest Complete",
        "Complete",
      ];
      if (!item.approvalRequired) {
        options = options.filter(
          (x) => x != "Pending Draft Approval" && x != "Draft Approved"
        );
      }
      return options.indexOf(item.status) + 1 + "/" + options.length;
    },
    addFiles() {
      this.$refs.uploader.$el.children[0].click();
    },
    async submitFiles() {
      this.uploadingDocument = true;
            let pending = this.fileList.filter((x) => !x.uploaded);
            for (let i = 0; i < pending.length; i++) {
                pending[i].loading = true
                let url = await URL.createObjectURL(pending[i].file);
                pending[i].fileIndex = this.lastIndex
                pending[i].uploaded = true
                this.toDataUrl(url, (data) => {
                    let obj = {
                        name: pending[i].name,
                        data: data,
                        type: pending[i].type,
                        size: pending[i].size,
                        fileIndex: pending[i].fileIndex,
                        shipmentId: this.shipment.id,
                        shipmentFolderId: this.currentFolder ? this.currentFolder.id : null,
                        uploaded: true
                    };
                    this.submitUpload(obj);
                });
                this.lastIndex++;
            }
        },
        async submitUpload(obj) {
            console.log("ENV", process.env)
            let Api = axios.create({
                // baseURL: "http://localhost:3000",
                // baseURL: "https://api.loglive.io",
                baseURL: process.env.NODE_ENV == 'production' ? 'https://api.loglive.ai' : process.env.NODE_ENV == 'staging' ? 'https://api.loglive.ai' : 'http://localhost:3000',
                headers: {
                    Authorization: `${this.$store.state.token}`,
                    apikey: `${this.$store.state.orgToken}`,
                },
            });
            let result = await Api.post("/upload/document", obj, {
                onUploadProgress: (progressEvent) => {
                    let find = this.fileList.findIndex(x => x.fileIndex == obj.fileIndex)
                    if (find > -1) {
                        this.fileList[find].progress = Math.ceil(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    }
                }
            });
            result = result.data;
            this.$emit('getShipmentDocuments')
            this.getTasks();
            this.uploadingDocument = false;
            this.documentKey++;
        },
        toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },
    documentMedia(document) {
      let result = [];
      let variationDocuments = [].concat.apply(
        [],
        document.variations.map((x) => x.shipmentDocumentTypeSupportings)
      );
      variationDocuments = variationDocuments.map((x) => x.shipmentDocumentId);
      let supportingDocuments = document.shipmentDocumentTypeSupportings
        .filter((x) => x.shipmentDocumentId)
        .map((x) => x.shipmentDocumentId);
      let variations = document.variations;
      let variationSupportingDocuments = [].concat.apply(
        [],
        variations.map((x) =>
          x.shipmentDocumentTypeSupportings
            .filter((y) => y.shipmentDocumentId)
            .map((y) => y.shipmentDocumentId)
        )
      );

      let taskAttachments = this.documentTasks(document);
      taskAttachments = [].concat.apply(
        [],
        taskAttachments.map((x) => x.taskAttachments)
      );
      taskAttachments = this.uploads
        .filter((x) => taskAttachments.map((y) => y.file).includes(x.key))
        .map((x) => x.id);
      supportingDocuments = [
        ...variationDocuments,
        ...supportingDocuments,
        ...variationSupportingDocuments,
        ...taskAttachments,
      ];
      result = this.uploads.filter((x) => supportingDocuments.includes(x.id));
      return result;
    },
    documentsRequired(task) {
      let requiredDocs = task.taskAttachments.filter(
        (x) => x.required && !x.file
      );
      let existingDocs = task.taskAttachments.filter((x) => x.file);
      let findMatches = requiredDocs.filter((x) =>
        existingDocs.map((y) => y.name).includes(x.name)
      );
      return (task.attachmentRequired && task.taskAttachments.filter((x) => x.file).length == 0) || (requiredDocs.length > 0 && findMatches.length == 0);
    },
    async deleteAttachment(item, task) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.deleteTaskAttachment(item);
          let index = task.taskAttachments.findIndex(
            (x) => x.id == item.id
          );
          task.taskAttachments.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    deleteServiceFailure() {
      this.deletingServiceFailure = true
      this.$confirm(
        "Are you sure you want to delete this service failure?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.serviceFailureItem.isDeleted = true;
          this.serviceFailureItem.isActive = false;
          await this.$API.updateServiceFailure(this.serviceFailureItem);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.getDocumentServiceFailures(this.documentItem);
          this.serviceFailureModal = false
          this.serviceFailureItem = {}
          this.deletingServiceFailure = false
        })
        .catch(() => {
          this.deletingServiceFailure = false
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },

    documentTasks(document) {
      let ids = [document.id, ...document.variations.map((x) => x.id)];
      let tasks = this.tasks.filter(
        (x) =>
          ids.includes(x.shipmentDocumentTypeId) ||
          ids.includes(x.parentShipmentDocumentId)
      );

      //TODO: Remove - Temp check for SI task
      // tasks = tasks.filter(x => {
      //   if(x.integrationCode !== 'GLOBAL_Inttra_SI_create') return true;

      //   return this.canViewSITask;
      // })

      return tasks;
    },
    async deleteDocument(item) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          item.isDeleted = true;
          item.isActive = false;
          await this.$API.updateShipmentDocument(item);
          let index = this.uploads.findIndex((x) => x.id == item.id);
          this.uploads.splice(index, 1);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async downloadDocument(doc) {
      this.docLoading = doc.id
      this.documentKey++;
      if (!doc.fullFile) {
        let file = await this.$API.downloadFile({
          key: doc.file,
        });
        doc.fullFile = file.file;
        doc.fileType = file.fileType;
      }

      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.fullFile;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      this.docLoading = null;
      this.documentKey++;
    },
    async downloadFile(doc) {
      this.docLoading = doc.id
      this.documentKey++;
      if (!doc.fullFile) {
        let file = await this.$API.downloadFile({
          key: doc.key,
        });
        doc.fullFile = file.file;
        doc.fileType = file.fileType;
      }

      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.fullFile;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
      this.docLoading = null;
      this.documentKey++;
    },
    editServiceFailure(failure) {
      this.serviceFailureItem = failure
      this.serviceFailureModal = true
    },
    confirmAction(action, task) {
      this.$confirm(action.confirmationText, "Confirm Action", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    
                  this.executeAction(action, task)
                })
    },
    async executeAction(action, task) {
      this.loadingAction = action.code + '_' + task.id;

      //Add service failure
      if (this.documentItem.submittedDate) {
        await this.addServiceFailure(false)
      }
      if(typeof task.payload == 'string'){
        task.payload = JSON.parse(task.payload)
      }
      if (task.documentationCode) {
        if (task.responseData) {
          if (typeof task.responseData == 'string') {
            task.responseData = task.payload
          }
          task.responseData.taskId = task.id;
        } else {
          task.payload.taskId = task.id;
        }
        let result = await this.$API.executeDocument(
          action.code,
          task.id,
          { ...task.payload, ...task.responseData }
        );
        task = { ...task, ...result };
      } else if (task.integrationCode) {
        if (task.responseData) {
          if (typeof task.responseData == 'string') {
            task.responseData = task.payload
          }
          task.responseData.taskId = task.id;
        } else {
          task.payload.taskId = task.id;
        }
        let result = await this.$API.executeIntegration(
          action.code,
          task.id,
          { ...task.payload, ...task.responseData },
          {
            type: 'action'
          }
        );
        task = { ...task, ...result };
      }
      this.loadingAction = null;
      setTimeout(() => {
        this.getTaskAttachments(task);
        this.refreshDocumentData()
      }, 2000)
      this.getTaskActions(task)
    },
    async completeForm(task) {
      if (this.documentTypes && this.documentTypes.length > 0) {
        let flattened = [...this.documentTypes, ...[].concat.apply([], this.documentTypes.map(x => x.variations))]
        let findDoc = flattened.find(
          (x) =>
            (x.id == task.shipmentDocumentTypeId ||
              x.id == task.parentShipmentDocumentId) &&
            x.shipmentActionId
        );
        if (findDoc) {
          if (findDoc.shipmentActionId) {
            let findAction = this.actions.find(
              (x) => x.id === findDoc.shipmentActionId
            );
            this.loadingForm=task.id
            this.form = await this.$API.getShipmentActionDetail(findAction.id);
            this.loadingForm= null
            this.form.form.formResponse = this.form.response;
            this.formPreview = true;
            this.formKey++
          }
        }
      }
    },
    formComplete(form) {
      let find = this.actions.find((x) => x.id == form.shipmentActionId);
      return find ? find.status == "Complete" : false;
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getDocumentIcon(name) {
      let type = name.split(".");
      let result = null;
      if (name.includes(".") && type.length > 0) {
        result = type.at(-1);
      }
      if (result == "xlsx") {
        result = "xls";
      }
      return result ? `/icons/${result}.svg` : null;
    },
    getInspectionColor(status) {
      switch (status) {
        case null:
          return 'orange'
        case 'New':
          return 'blue'
        case 'Resubmitted':
          return 'blue'
        case 'Approved':
          return 'green'
        case 'Rejected':
          return 'red'
      }
    },
    getIcon(type) {
      switch (type.toLowerCase()) {
        case "buyer":
          return "shopping_cart";
        case "consignee":
          return "call_received";
        case "shipper":
          return "directions_boat";
        case "forwarder":
          return "fast_forward";
        case "notify party":
          return "notifications";
      }
    },
    async getServiceFailureCategories() {
      this.serviceFailureCategories = await this.$API.getServiceFailureCategories();
    },
    async getTasks(){
      this.refreshTasks = true;
      this.updatedTasks =  await this.$API.getShipmentDocuments(this.shipment.id);
      this.refreshTasks = false;
      },
    async getDocumentComments(id){
      this.commentsLoading = true
      this.comments = await this.$API.getShipmentDocumentTypeComments(id)
      this.commentsLoading = false
    },
    async getDocumentLog(id) {
      this.loadingDocumentLog = true
      this.documentLog = await this.$API.getShipmentDocumentLog(id)
      this.loadingDocumentLog = false
    },
    async getAllDocuments(id) {
      this.documentsLoading = true
      this.allDocuments = await this.$API.getAllDocumentsLinked(id)
      this.documentsLoading = false
    },
    async getDocumentServiceFailures(document) {
      this.loadingServiceFailures = true
      this.serviceFailures = await this.$API.getServiceFailureByShipmentDocument(document.id);
      this.loadingServiceFailures = false
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "orange";
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "in progress":
          return "indigo";
        case "submitted":
          return "blue";
        case "resubmitted":
          return "cyan";
        case "rejected":
          return "red";
        case "draft":
          return "orange";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case "query":
          return "purple";
        case 'in transnit':
          return 'orange'

        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "error":
          return "red";
        case "closed":
          return "red";
        case "cancelled":
          return "red";
        case "not created":
          return "red";
        case "validation failed":
          return "red";
        case (status.toLowerCase()).includes("pending"):
          return "orange";
        case (status.toLowerCase()).includes("approved"):
          return "green";
        case (status.toLowerCase()).includes("failed"):
          return "red";
        case (status.toLowerCase()).includes("revoked"):
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "warning";
        case "not required":
          return "close";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "rejected":
          return "cancel";
        case "submitted":
          return "mark_email_read";
        case "resubmitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "file generated":
          return "task";
        case "error":
          return "cancel";
        case "not created":
          return 'error';
        case 'approved':
          return 'fact_check'
        case 'validation failed':
          return 'error'
        case (status.toLowerCase()).includes("pending"):
          return "pending";
        case (status.toLowerCase()).includes("approved"):
          return "fact_check";
        case (status.toLowerCase()).includes("failed"):
          return "error";
        case (status.toLowerCase()).includes("revoked"):
          return "error";
        default:
          return "info";
      }
    },
    async getTaskActions(task) {
      if(task.id){
        this.loadingTaskActions.push(task.id);
      task.taskActions = []
      task.taskActions = await this.$API.getTaskActions(task.id);
      this.actionKey++;
      this.loadingTaskActions.splice(this.loadingTaskActions.indexOf(task.id), 1);
      }
     
    },
    getTaskStatus(task) {
      switch (task.status) {
        case "New":
          return { name: task.status, icon: "grade", color: "orange" };
        case "Not started":
          return { name: task.status, icon: "schedule", color: "grey" };
        case "Pending":
          return { name: task.status, icon: "pending", color: "orange" };
        case "Resubmitted":
          return { name: task.status, icon: "sync", color: "orange" };
        case "In Progress":
          return { name: task.status, icon: "sync", color: "blue" };
        case "Complete":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "File Generated":
          return { name: task.status, icon: "task", color: "orange" };
        case "Success":
          return { name: task.status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: task.status, icon: "cancel", color: "grey" };
        case "Not Created":
          return { name: task.status, icon: "error", color: "red" };
        case "Rejected":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Draft":
          return { name: task.status, icon: "edit_note", color: "orange" };
        case "Error":
          return { name: task.status, icon: "error", color: "red" };
        case "Failed":
          return { name: task.status, icon: "cancel", color: "red" };
        case "Submitted":
          return { name: task.status, icon: "mark_email_read", color: "blue" };
        case "Not Required":
          return { name: task.status, icon: "close", color: "grey" };
        case "Approved":
          return { name: task.status, icon: "fact_check", color: "green" };
        case "Validation Failed":
          return { name: task.status, icon: "error", color: "red" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: task.status,
          };
      }
    },
    async getDocumentDetails(id) {
      let result = await this.$API.getShipmentDocumentTypeDetail(id);
      this.documentItem = { ...this.documentItem, ...result }
      // this.$message.success('Document details updated')
    },
    async getTaskAttachments(task) {
      this.loadingAttachments.push(task.id);
      task.taskAttachments = await this.$API.getTaskAttachments(
        task.id
      );
      this.attachmentKey++;
      this.loadingAttachments.splice(
        this.loadingAttachments.indexOf(task.id),
        1
      );
      this.$emit('getShipmentDocuments')
    },
    matchingTags(item) {
      let result = []
      let allowedTags = this.documentItem.shipmentDocumentTypeSupportings.map(x => x.supportingDocumentId)
      result = item.shipmentDocumentClassifications.filter(x => allowedTags.includes(x.supportingDocumentId)).map(x => x.supportingDocument)
      return result
    },
    async nextStatus(item) {
      let index = this.availableStatuses.indexOf(item.status);
      if (this.availableStatuses.length > index + 1) {
        item.status = this.availableStatuses[index + 1];
        index = this.availableStatuses.indexOf(item.status);
        this.statusStepper = index + 1;
      }
      await this.$API.updateShipmentDocumentType({
        id: item.id,
        status: item.status,
      });
      this.$emit('updateDocument', this.documentItem)
    },
    async previewDocument(doc) {
      this.previewLoading = doc.id;
      this.documentKey++
      this.previewItem = this.documents.find(x => x.key == doc.file)
      if (!this.previewItem) {
        this.previewItem = {}
      }
      if (!this.previewItem.fullFile) {
        let file = await this.$API.downloadFile({
          key: doc.file,
        });
        this.previewItem.fullFile = file.file;
        this.previewItem.fileType = file.fileType;
      }

      this.previewModal = true
      this.previewLoading = null
      this.documentKey++
    },
    refreshDocumentData() {
      // this.getDocumentDetails(this.documentItem.id)
      console.log('Triggered refresh')
      this.$emit('refreshDocument', this.documentItem)
      this.getDocumentServiceFailures(this.documentItem)
      this.getDocumentLog(this.documentItem.id)
      this.getDocumentComments(this.documentItem.id)
    },
    async saveAttachment() {
      this.savingDocumentAttachment = true;
      this.previewLoading = null
      this.docLoading = false
      let obj = this.attachmentItem.taskAttachment;
      delete obj.id;
      delete obj.createdAt;
      delete obj.updatedAt;
      obj.file = this.attachmentItem.file;
      await this.$API.createTaskAttachment(obj);
      this.getTaskAttachments(this.attachmentItem.task);
      this.addAttachmentModal = false;
      this.attachmentItem = {};
      this.savingDocumentAttachment = false;
    },
    async saveDocument(triggerClose=false) {
      this.promptSaveModal = false
      this.promptSave = false
      this.savingDocument = true;
      await this.$API.updateShipmentDocumentType(this.documentItem);
      this.$emit('updateDocument', this.documentItem)
      this.documentModal = false;
      // this.documentItem = {};
      this.savingDocument = false;
      
      if(triggerClose){
        this.closeModal()
      }
    },
    async saveServiceFailure() {
      this.savingServiceFailure = true;
      if (this.serviceFailureItem.id) {
        await this.$API.updateServiceFailure(this.serviceFailureItem)
      } else {
        this.serviceFailureItem.type = 'document'
        this.serviceFailureItem.shipmentDocumentTypeId = this.documentItem.id
        this.serviceFailureItem.shipmentId = this.documentItem.shipmentId
        await this.$API.createServiceFailure(this.serviceFailureItem);
        // let res = await this.$API.updateShipmentDocumentType({
        //   id: this.documentItem.id,
        //   status: this.documentItem.status,
        // });
      }
      await this.getDocumentServiceFailures(this.documentItem)
      await this.getDocumentLog(this.documentItem.id)
      await this.getDocumentComments(this.documentItem.id)
      this.serviceFailureModal = false;
      this.serviceFailureItem = {};
      // this.succesfullyUpdatedServiceFailure = true;
      this.savingServiceFailure = false;
    },
    serviceFailureSubcategories(categoryId) {
      let result = []
      if (categoryId) {
        result = this.serviceFailureCategories.find(x => x.id == categoryId).serviceFailureSubcategories
      }
      return result
    },
    async checkParentDoc(task) {
        if(this.documentItem.status == "Complete") {
        this.$confirm("Are you sure you want to Start this task, the system indicates that this document has already been completed?", "Confirm Start Task", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    
                  this.startTask(task)
                })
      } else {
        this.startTask(task)
      }
    },
    async startTask(task) {
      if(task.taskTypeId === TASK_TYPES.FORM_DOCUMENT) {
        this.handleFormDocumentIntegration(task) 
      } else {
        if(!this.loadingTaskActions.includes(task.id)) {
        this.loadingTaskActions.push(task.id);
        task.status = "In Progress";
        await this.$API.startTask(task);
        this.loadingTaskActions.splice(
          this.loadingTaskActions.indexOf(task.id),
          1
        );
        // await this.$API.updateTask({
        //   id: task.id,
        //   status: "Pending",
        // });
        this.$emit('update', task)
        }
      }
    },
    // async startTasks() {
    //   let tasks = this.documentTasks(this.documentItem);
    //   tasks = tasks.filter((x) => x.status == "Not started");
    //   this.documentItem.integrationStatus = "Pending";
    //   await this.$API.updateShipmentDocumentType({
    //     id: this.documentItem.id,
    //     integrationStatus: this.documentItem.integrationStatus,
    //   });
    //   await this.$API.startDocumentTasks(this.documentItem.id, {
    //     status: "Pending",
    //   });
    //   for (let i = 0; i < tasks.length; i++) {
    //     tasks[i].status = "Pending";
    //   }
    // },
    async submitForm(response) {
      this.form.response = response;
      this.form.userId = this.$store.state.user.id;
      this.form.user = this.$store.state.user;
      this.form.status = "Complete";
      let action = this.actions.find(
              (x) => x.id === this.form.id
            );
            action.status = "Complete";
      await this.$API.submitShipmentActions(this.form);
      this.formPreview = false;
      this.form = {};
    },

    update(doc) {
      this.task = doc
      let findTask = this.tasks.findIndex(x => x.id == doc.id)
      if (findTask > -1) {
        this.tasks[findTask] = doc
        this.statusKey++
      }
      this.$emit('update', doc)
    },
    updateDocument(doc) {
      this.$emit('updateDocument', doc)
    },

    addComment(status) {
      if (status == 'Query') {
        this.commentTypes = ['Query']
      }
      else {
        this.commentTypes = ['General', 'Invoice','Query']
      }

      this.commentModal = true

    },
    async saveComment() {
      this.savingComment = true

      if (this.commentItem && !this.commentItem.commentType) {
        this.commentItem.commentType = 'Query'
      }

      console.log('this.commentItem', this.commentItem)
      let res = await this.$API.addShipmentDocumentTypeComment({
        shipmentDocumentTypeId: this.documentItem.id,
        comment: this.commentItem.comment,
        commentType: this.commentItem.commentType,
        shipmentId: this.documentItem.shipmentId,
        createdById: this.$store.state.user.id,
      })
      await this.getDocumentLog(this.documentItem.id)

      // if (res && this.commentItem.commentType == 'General' || this.commentItem.commentType == 'Invoice') {
      //   await this.getDocumentLog(this.documentItem.id)
      // }
      this.commentItem = {}
      this.savingComment = false
      this.commentModal = false


    },


    async updateStatus(status, overide) {
      this.documentItem.status = status;
      let date = new Date()
      console.log(status)
      if (status == 'Resubmitted') {
        await this.addServiceFailure(true)
      }
      if (status == 'Query') {
        this.addComment(status)
      }
      if (!this.commentModal) {
        let index = this.availableStatuses.indexOf(status);
        this.statusStepper = index + 1;
        console.log('status', this.documentItem.status)
        let res = await this.$API.updateShipmentDocumentType({
          id: this.documentItem.id,
          status: this.documentItem.status,
        });
        
        this.$message.success('Status updated!')
        if (res.status == 'Submitted' && overide == 1) {
          this.documentItem.submittedDate = date.toISOString().slice(0, 10)
        }
        if (res.status == 'Complete' && overide == 1) {
          this.documentItem.completedDate = date.toISOString().slice(0, 10)
        }
        if (res.status == 'Resubmitted' && overide == 1) {
          this.documentItem.resubmitDate = date.toISOString().slice(0, 10)
        }
      this.$emit('updateDocument', this.documentItem)
        await this.getDocumentLog(this.documentItem.id)
        this.availableActions();
        await this.getDocumentServiceFailures(this.documentItem)
      }
    },

    async updateCompletedDate() {
        this.savingDate = true
        await this.$API.updateShipmentDocumentType({
          id: this.documentItem.id,
          status: 'Complete',
          completedDate: this.documentItem.completedDate,
        });
        this.documentItem.status = 'Complete'
        this.$emit('updateDocument', this.documentItem)

        let status = 'Complete'
        this.$message.success('Successfully updated!')
        // await this.updateStatus(status, 0)
        this.completedDateModal = false
        await this.getDocumentLog(this.documentItem.id)
        // this.$message.success('Completed date updated!')
        this.savingDate = false
    },

    async updateResubmitDate() {
        this.savingDate = true
        let status = 'Resubmitted'
        this.documentItem.status = status
        this.documentItem.completedDate = null
        await this.$API.updateShipmentDocumentType({
          id: this.documentItem.id,
          resubmitDate: this.documentItem.resubmitDate,
          completedDate: null,
          status: status,
        });
        this.$message.success('Successfully updated!')
      this.$emit('updateDocument', this.documentItem)
        await this.addServiceFailure(true)
        // await this.updateStatus(status,0)
        // await this.getDocumentLog(this.documentItem.id)
        // this.actionOptions();
        // await this.getDocumentServiceFailures(this.documentItem)
        // this.$message.success('Resubmit   updated!')
        await this.getDocumentLog(this.documentItem.id)
        this.resubmitDateModal = false
        this.savingDate = false
    },
    async updateSubmissionDate() {
        this.savingDate = true
        let status = 'Submitted'
        this.documentItem.status = status
        await this.$API.updateShipmentDocumentType({
          id: this.documentItem.id,
          submittedDate: this.documentItem.submittedDate,
          status: status
        });
        this.$message.success('Successfully updated!')
      this.$emit('updateDocument', this.documentItem)

        // await this.updateStatus(status, 0)
        this.submittedDateModal = false
        await this.getDocumentLog(this.documentItem.id)

        // this.$message.success('Submitted date updated!')
        this.savingDate = false
    },
    async updateDocumentLogItem(item) {
      this.savingDocumentLogItem.push(item.id)
      await this.$API.updateShipmentDocumentTypeLog(item)
      
      this.savingDocumentLogItem.splice(this.savingDocumentLogItem.indexOf(item.id), 1)
    },
    viewDocument(document) {
      this.documentLog = []
      this.documentItem = document;
      this.documentTab = "info";
      this.expandedProfile = false;
      this.expandedTask = null;
      this.statusStepper = this.availableStatuses.indexOf(document.status) + 1;
      for (let i = 0; i < this.documentTasks(document).length; i++) {
        this.getTaskAttachments(this.documentTasks(document)[i])
        this.getTaskActions(this.documentTasks(document)[i])
      }
      this.documentModal = true;
      this.getDocumentServiceFailures(document);
      this.getDocumentLog(document.id)
      this.getDocumentComments(document.id)
    },
    viewSubtasks(task) {
      let findIndex = this.displayedSubtasks.findIndex(x => x == task.id)
      if (findIndex > -1) {
        this.displayedSubtasks.splice(findIndex, 1)
      } else {
        this.displayedSubtasks.push(task.id)
      }
      this.subtaskKey++
    },
    viewTask(task) {
      this.task = task;
      this.taskModal = true;
    },
    async closeModal(ignorePrompt = false) {
      // if(!ignorePrompt && this.promptSave){
      //   this.promptSaveModal = true
      // } else {
      this.serviceFailures = []
      this.documentLog = []
      // this.documentItem = []
      this.promptSaveModal = false
      this.promptSave = false
      this.$emit('close')
      // }
   
    },
    viewSubtask(task){
      console.log("Viewing subtask", task)
      this.task = task
    },
    handleFormDocumentIntegration(task) {
      if(task.integrationCode === INTEGRATION_CODES.INTTRA_SI_CREATE) {
        this.$refs.shippingInstructionDialog.open({
          intergrationCode: SI_INTEGRATION_CODES.INTTRA,
          shipmentId: this.shipment.shipmentReference,
          actionType: SI_FORM_ACTION_TYPES.EMIT,
          actionInput: {
            task
          }
        })
      }
    },

    async handleFormDocumentIntegrationTaskSubmit({ actionInput, actionOutput }) {
      const { task } = actionInput

      if(task.integrationCode === INTEGRATION_CODES.INTTRA_SI_CREATE) {
        this.$refs.shippingInstructionDialog.close()

        task.status = TASK_STATUS.PENDING
        await this.$API.updateTask({
          id: task.id,
          status: TASK_STATUS.PENDING,
          requestData: actionOutput
        });
      }
    },
    dateSelectionDisabled(type){
      let disabled = false

      switch (type) {
        case 'submitted':
          if(this.documentItem.completedDate){ disabled = true }
          break;
        case 'resubmitted':
          if(!this.documentItem.submittedDate){ disabled = true }
          break;
        case 'completed':
          if(!this.documentItem.submittedDate || ( this.documentItem.submittedDate && this.documentItem.completedDate)){ disabled = true }
          break;
      }
           
      if (this.userScope && this.userScope.access === 'admin') {
        disabled = false
      } 

      if (this.shipment && this.shipment.documentationStatus === 'CLOSED'){
        disabled = true
      }

      // if (this.documentItem.status === 'Complete') {
      //   disabled = true
      // }
      
      return disabled
    },
    async updateSaveDocument(type){
      if(this.documentItem.id){
        await this.$API.updateShipmentDocumentType({
          id: this.documentItem.id,
          [type]: this.documentItem[type]
        })
      this.$emit('updateDocument', this.documentItem)
        this.$message.success('Successfully updated!')
      }
    },
    async updateResponsibleParty(){
      await this.$API.updateShipmentDocumentType({
        id: this.documentItem.id,
        responsiblePartyType: this.documentItem.responsiblePartyType
      })
      this.$emit('updateDocument', this.documentItem)
      this.$message.success('Successfully updated!')
    },
    async updateReference(){
      if (this.referenceTimeout) {
        clearTimeout(this.referenceTimeout);
      }
      this.referenceTimeout = setTimeout(async () => {
        await this.$API.updateShipmentDocumentType({
        id: this.documentItem.id,
        reference: this.documentItem.reference
      })
      this.$emit('updateDocument', this.documentItem)
      this.$message.success('Successfully updated!')
      }, 500);
    },
    updateSavePrompt(){
      this.promptSave = true
      console.log("Prompt", this.promptSave)
    }
  },
};
</script>
<style scoped>
/* .activeClass {} */

.v-stepper__step {
  flex: 1;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  z-index: 9999;
  position: absolute;
  width: 100%;
  cursor: pointer;
}

.orange-text {
  color: orange;
}


.v-stepper__header {
  height: 80px;
}
</style>
