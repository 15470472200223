<template>
    <div>
        <v-row class="pa-3" align="center" no-gutters>
            <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.mobile">
                <v-text-field hide-details prepend-inner-icon="search" placeholder="Search" v-model="search" outlined
                    dense rounded clearable></v-text-field>
            </v-col>
            <v-chip v-if="!loadingFiles && documentsMap" color="blue-grey" class="mx-1" small>
                <v-icon small left>description</v-icon> {{ documentsMap.length }} Documents
            </v-chip>
            <v-chip color="red" class="mx-1" small>
                <v-icon small left>filter_alt_off</v-icon> Clear
                Filters
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn @click="closeFiles" icon color="redPop"><v-icon>close</v-icon> </v-btn>
        </v-row>
        <v-card-text v-if="!$vuetify.breakpoint.mobile" style="overflow-x: scroll; contain: content;">
            <div :style="{ width: tableWidth }">
                <table :width="'100%'">
                    <tr class="mx-0 px-0">
                        <td class="text-center mx-0 px-0"
                            style="cursor: grab;border-right: 1px solid grey;min-height: 20px;min-width: 20px;"
                            v-for="(header, index) in headers.filter(x => x.show)" :key="index"
                            :width="header.width ?? '150px'">
                            <b style="font-size: 12px" v-if="header.value != 'actions'">{{ header.text }}</b>
                            <v-btn v-if="!header.disableSort" class="ml-1" icon x-small
                                @click="changeSortBy(header.value)">
                                <v-icon v-if="sortBy != header.value" small color="grey">arrow_upward</v-icon>
                                <v-icon v-else-if="sortDesc" small>arrow_downward</v-icon>
                                <v-icon v-else small>arrow_upward</v-icon>
                            </v-btn>
                            <v-badge v-if="!header.disableFilter" :content="
                                filters[header.value]
                                ? filters[header.value].length
                                : false
                            " :value="
                                filters[header.value] &&
                                filters[header.value].length > 0
                            " color="primary" offset-y="10">
                                <v-menu offset-y left :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" x-small color="grey" icon><v-icon
                                                small>filter_alt</v-icon></v-btn>
                                    </template>
                                    <v-card style="
                                    background: var(--v-modal-base) !important;
                                    border-radius: 20px;
                                ">
                                        <v-card-text>
                                            <v-list dense style="
                                            background: var(--v-modal-base) !important;
                                            border-radius: 20px;
                                        ">
                                                <v-text-field hide-details rounded dense outlined clearable
                                                    prepend-inner-icon="search" placeholder="Search" autofocus
                                                    v-model="searchHeaders[header.value]"
                                                    @input="searchKey++;"></v-text-field>
                                                <v-list dense style="
                                            max-height: 50vh;
                                            overflow-y: auto;
                                            background: var(--v-modal-base) !important;
                                            " :key="searchKey">
                                                    <v-list-item v-for="(item, index) in headerOptions(
                                                header.value
                                            )" :key="index">
                                                        <v-list-item-action class="pr-0">
                                                            <v-checkbox v-model="filters[header.value]"
                                                                :value="item.value" hide-details
                                                                @change="calculateFilteredResults()" dense></v-checkbox>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ item.name }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-list-item v-if="
                                                headerOptions(header.value).length == 0
                                            ">
                                                        <v-list-item-content class="text-center">
                                                            <span style="font-size: 12px">No results found</span>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </v-badge>
                        </td>
                    </tr>
                </table>
                <v-divider v-if="!loadingFiles"></v-divider>
                <v-progress-linear indeterminate v-if="loadingFiles" style="height: 1px;"></v-progress-linear>
                <div class="mt-1 pt-1 px-1" :style="{ width: '100%' }" v-if="documents.length > 0"
                    style="contain: content; overflow-x: scroll;">
                    <v-virtual-scroll v-if="documentsMap.length > 0" :bench="10" :items="documentsMap"
                        :height="calculatedHeight" :width="'100%'" item-height="35" class="px-1">
                        <template v-slot:default="{ item }">
                            <div class="text-center my-0 py-0" :style="{
                                display: 'flex',
                                'flex-direction': 'row',
                                width: '100%',
                                'background-color': 'transparent',
                                cursor: 'pointer',
                            }">
                                <td v-for="(header, index) in headers.filter(x => x.show)" :key="index" :style="{
                                width: header.width ?? '150px',
                                'font-size': '11px',
                                }" class="text-center mt-0 mb-0 pb-0">
                                    <div v-if="header.value == 'documentationStatus'" class="text-center">
                                        <v-chip small outlined>
                                            {{ item.status }}</v-chip>
                                    </div>
                                    <div v-else-if="header.value == 'assignedDocumentUserName'">
                                        <v-tooltip top>
                                            <template v-slot:activator="{on}">
                                                <v-chip v-on="on"
                                                    @click="addToFilter(item.assignedDocumentUserName, 'assignedDocumentUserName')"
                                                    style="background-color: hsla(0,0%,100%,.2) !important;border: none;"
                                                    small class="pl-0">
                                                    <v-avatar
                                                        style="height: 24px !important; width: 24px !important; min-width: 24px !important;"
                                                        v-if="item.assignedUser"
                                                        :color="item.assignedUser.avatar ? 'white' : 'secondary'"
                                                        class="mr-2">
                                                        <v-img v-if="item.assignedUser.avatar" contain
                                                            :src="item.assignedUser.avatar"></v-img>
                                                        <v-icon v-if="!item.assignedUser.avatar">person</v-icon>
                                                    </v-avatar>
                                                    <v-icon v-else-if="!item.assignedUser" v-on="on"
                                                        class="mr-1">person</v-icon>
                                                    <span v-if="item.assignedUser">
                                                        {{ item.assignedUser.firstname }} {{ item.assignedUser.surname
                                                        }}
                                                    </span>
                                                </v-chip>
                                            </template>
                                            <span>{{ item.assignedUser.firstname }} {{ item.assignedUser.surname
                                                }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'responsiblePartyType'">
                                        <v-tooltip top>
                                            <template v-slot:activator="{on}">
                                                <v-chip v-if="item.responsiblePartyType" color="white" v-on="on" small
                                                    outlined class="px-1 primaryText--text"
                                                    @click="addToFilter(item[header.value], header.value)">
                                                    <v-icon small
                                                        v-if="item.responsiblePartyType == 'forwarder'">fast_forward</v-icon>
                                                    <v-icon small
                                                        v-if="item.responsiblePartyType == 'shipper'">directions_boat</v-icon>
                                                    <v-icon small
                                                        v-if="item.responsiblePartyType == 'buyer'">shopping_cart</v-icon>
                                                    <v-icon small
                                                        v-if="item.responsiblePartyType == 'consignee'">call_received</v-icon>
                                                    <v-icon small
                                                        v-if="item.responsiblePartyType == 'notify party'">notifications</v-icon>
                                                </v-chip>
                                            </template>
                                            <span>{{item.responsiblePartyType ?
                                                item.responsiblePartyType.charAt(0).toUpperCase() +
                                                item.responsiblePartyType.slice(1) : '' }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'document'">
                                        <div class="d-flex">
                                            <v-btn icon small plain @click="viewDocument(item)"
                                                :loading="documentLoading == item.id">
                                                <v-icon small>launch</v-icon>
                                            </v-btn>
                                            <v-chip class="primaryText--text" small outlined style="border:none"
                                                @click="addToFilter(item[header.value], header.value)">
                                                <span
                                                    :class="item.kpiCategory == 'OVERDUE' ? 'overdue' : item.kpiCategory == 'DANGER' ? 'inDanger' :item.kpiCategory == 'GOOD' ? 'withinKPI' : ''">{{
                                                    item.document }}</span>
                                                <!-- <v-icon right small v-if="item.kpiCategory"
                                            :color="item.kpiCategory == 'OVERDUE' ? 'error' : item.kpiCategory == 'DANGER' ? 'warning' : 'success'"
                                            >
                                            {{ item.kpiCategory == 'OVERDUE' ? 'error' : item.kpiCategory == 'DANGER' ? 'warning' : 'check_circle' }}
                                            </v-icon> -->
                                            </v-chip>
                                        </div>
                                    </div>
                                    <div v-else-if="header.value == 'fileNumber'" class="text-center">
                                        <v-btn small plain icon @click="viewShipment(item)"><v-icon
                                                small>launch</v-icon></v-btn>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" small class="primaryText--text" outlined
                                                    style="border: none"
                                                    @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar class="smallerAvatar" left :color="item.daysFromToday >= 5 ? 'redPop' : 
                                                (item.daysFromToday >= 3) && (5 > item.daysFromToday) ? '#dfaa49' : 
                                                (item.daysFromToday >= 0) && (3 > item.daysFromToday) ? 'tertiary' :
                                                0 > item.daysFromToday ? 'grey' : '' 
                                                ">
                                                        <b style="color: white; font-size: 10px">
                                                            {{ 0 > item.daysFromToday ? '-' : item.daysFromToday }}
                                                        </b>
                                                    </v-avatar>
                                                    {{ item.fileNumber }}
                                                </v-chip>
                                            </template>
                                            <span>{{
                                                $Format.capitalizeFirstLetter(
                                                item.movementType
                                                )
                                                }}
                                                Shipment</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'vesselVoyage'" class="text-left">
                                        <v-chip v-if="item.vesselVoyage"
                                            @click="addToFilter(item[header.value], header.value)" small
                                            class="primaryText--text" outlined
                                            style="border: none; font-size: 11px"><span>{{ item.vesselVoyage }}</span>
                                        </v-chip>
                                    </div>
                                    <div v-else-if="header.value == 'portOfLoadValue'" class="text-center">
                                        <v-tooltip top v-if="item.portOfLoadValue">
                                            <template v-slot:activator="{ on }">
                                                <v-chip outlined style="border: none; font-size: 11px" v-on="on" small
                                                    @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="20" left>
                                                        <v-img contain
                                                            :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                    </v-avatar>
                                                    {{ item.portOfLoadValue }}
                                                </v-chip>
                                            </template>
                                            <span style="font-size: 12px">{{
                                                item.portOfLoadCity
                                                }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'finalDestinationValue'" class="text-center">
                                        <v-tooltip top v-if="item.finalDestinationValue">
                                            <template v-slot:activator="{ on }">
                                                <v-chip outlined style="border: none; font-size: 11px" v-on="on" small
                                                    @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="20" left>
                                                        <v-img contain
                                                            :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                    </v-avatar>
                                                    {{ item.finalDestinationValue }}
                                                </v-chip>
                                            </template>
                                            <span style="font-size: 12px">{{item.finalDestinationCity}}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'consigneeName'" class="text-left">
                                        <v-tooltip top v-if="item.consigneeName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.consigneeName" small class="primaryText--text"
                                                    outlined v-on="on" style="border: none"
                                                    @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="14" class="smallerAvatar"
                                                        :color="item.consignee.logo ? 'white' : 'secondary'" left>
                                                        <v-img style="" v-if="item.consignee.logo"
                                                            :src="item.consignee.logo" contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.consigneeName.charAt(0) }}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ $Format.removePtyLtd(item.consigneeName) ?? ''}}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.consigneeName ?? ''}}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'buyerName'" class="text-left">
                                        <v-tooltip top v-if="item.buyerName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.buyerName" small class="primaryText--text" v-on="on"
                                                    outlined @click="addToFilter(item[header.value], header.value)"
                                                    style="border: none">
                                                    <v-avatar size="14" class="smallerAvatar"
                                                        :color="item.buyer && item.buyer.logo ? 'white' : 'secondary'"
                                                        left>
                                                        <v-img v-if="item.buyer.logo" :src="item.buyer.logo"
                                                            contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.buyerName ? item.buyerName.charAt(0) : ''}}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ item.buyerName ? $Format.removePtyLtd(item.buyerName) : '' }}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.buyerName }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'customerName'" class="text-left">
                                        <v-tooltip top v-if="item.customerName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.customerName" small class="primaryText--text"
                                                    v-on="on" outlined style="border: none"
                                                    @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="14" class="smallerAvatar"
                                                        :color="item.customer && item.customer.logo ? 'white' : 'secondary'"
                                                        left>
                                                        <v-img v-if="item.customer.logo" :src="item.customer.logo"
                                                            contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.customerName.charAt(0) }}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ $Format.removePtyLtd(item.customerName) }}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.customerName }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'shipperName'" class="text-left">
                                        <v-tooltip top v-if="item.shipperName">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-if="item.shipperName" small class="primaryText--text"
                                                    v-on="on" outlined style="border: none"
                                                    @click="addToFilter(item[header.value], header.value)">
                                                    <v-avatar size="14" class="smallerAvatar"
                                                        :color="item.shipper && item.shipper.logo ? 'white' : 'secondary'"
                                                        left>
                                                        <v-img v-if="item.shipper.logo" :src="item.shipper.logo"
                                                            contain></v-img>
                                                        <h4 v-else style="color: white">
                                                            {{ item.shipperName.charAt(0) }}
                                                        </h4>
                                                    </v-avatar>
                                                    {{ $Format.removePtyLtd(item.shipperName) }}
                                                </v-chip>
                                            </template>
                                            <span>{{ item.shipperName }}</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'etd'">
                                        <v-chip v-if="item.etd" small class="primaryText--text" outlined
                                            style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            {{ $Format.formatDate(item.etd).dayMonthYear }}
                                        </v-chip>
                                    </div>
                                    <div v-else-if="header.value == 'eta'">
                                        <v-chip v-if="item.eta" small class="primaryText--text" outlined
                                            style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            {{ $Format.formatDate(item.eta).dayMonthYear }}
                                        </v-chip>
                                    </div>
                                    <div v-else-if="header.value == 'submittedDate'">
                                        <v-tooltip top color="success" v-if="item.submittedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text" small outlined
                                                    style="border: none">
                                                    {{ item.submittedDate }}
                                                </v-chip>
                                            </template>
                                            <span>Date of Submission</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="!item.submittedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text" small outlined
                                                    style="border: none; color:#5f5f5f !important">
                                                    {{ item.submittedDue }}
                                                </v-chip>
                                            </template>
                                            <span>Submission Due Date</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else-if="header.value == 'completedDate'">
                                        <v-tooltip top color="success" v-if="item.completedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text" small outlined
                                                    style="border: none">
                                                    {{ item.completedDate }}
                                                </v-chip>
                                            </template>
                                            <span>Date of Completion</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="!item.completedDate">
                                            <template v-slot:activator="{ on }">
                                                <v-chip v-on="on" class="primaryText--text" small outlined
                                                    style="border: none; color:#5f5f5f !important">
                                                    {{ item.completedDue }}
                                                </v-chip>
                                            </template>
                                            <span>Completion Due Date</span>
                                        </v-tooltip>
                                    </div>



                                    <div v-else-if="header.value == 'status'" class="text-left">
                                        <v-menu right offset-x :close-on-content-click="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :color="chatColor(item.comments)" v-on="on" class="mb-0 pb-0"
                                                    v-bind="attrs" small icon @click="getShipmentDocumentLog(item.id)">
                                                    <v-icon small>forum</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card width="20vw" style="overflow-y: hidden">
                                                <v-subheader>{{ item.document }}</v-subheader>
                                                <v-divider v-if="!loadingComments"></v-divider>
                                                <v-progress-linear indeterminate style="height: 1px;"
                                                    v-if="loadingComments"></v-progress-linear>
                                                <v-card height="40vh" width="inherit" style="overflow-y: auto" flat>
                                                    <v-list>

                                                        <v-list-item
                                                            v-if="!loadingComments && (!item.comments || item.comments.length === 0)">
                                                            <v-list-item-content class="text-center">
                                                                <span style="font-size: 12px; color: grey">No
                                                                    comments.</span>
                                                            </v-list-item-content>
                                                        </v-list-item>


                                                        <v-list-item v-else v-for="(comment, index) in item.comments"
                                                            :key="index" :id="'comment_' + index" class="py-0 my-1">
                                                            <!-- <v-list-item-avatar
                                                                v-if="comment.createdBy && comment.createdBy.id !== $store.state.user.id">
                                                                <v-tooltip top v-if="comment.createdBy">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-avatar v-bind="attrs" v-on="on"
                                                                            style="cursor: pointer" color="secondary"
                                                                            size="48" class="mt-0 pt-0">
                                                                            <span
                                                                                v-if="comment.createdBy && !comment.createdBy.avatar"
                                                                                class="primaryText--text text-h5">
                                                                                {{$Format.avatarAbbreviation(comment.createdBy?.firstname
                                                                                + ' ' + comment.createdBy?.surname)}}
                                                                            </span>
                                                                            <img v-else-if="comment.createdBy?.avatar"
                                                                                :src="comment.createdBy?.avatar"
                                                                                referrerpolicy="no-referrer" />
                                                                        </v-avatar>
                                                                    </template>
                                                                    <span style="font-size: 12px">{{
                                                                        comment.createdBy?.firstname }} {{
                                                                        comment.createdBy?.surname }}</span>
                                                                </v-tooltip>
                                                            </v-list-item-avatar> -->
                                                            <v-list-item-avatar
                                                                v-if="comment.user && comment.user.id == $store.state.user.id">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-avatar v-bind="attrs" v-on="on"
                                                                            style="cursor: pointer" color="secondary"
                                                                            size="48" class="mt-0 pt-0">
                                                                            <span v-if="!comment.user.avatar"
                                                                                class="primaryText--text text-h5">
                                                                                {{ $Format.avatarAbbreviation(comment.user.firstname
                                                                                +" " +comment.user.surname)}}
                                                                            </span>
                                                                            <img v-else :src="comment.user.avatar"
                                                                                referrerpolicy="no-referrer" />
                                                                        </v-avatar>
                                                                    </template>
                                                                    <span style="font-size: 12px" v-if="comment.user">{{
                                                                        comment.user.firstname }}
                                                                        {{ comment.user.surname }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content
                                                                :class="comment.createdBy && comment.createdBy.id === $store.state.user.id ? 'text-right' : 'text-left'">
                                                                <v-list-item-title class="text-wrap">
                                                                    {{ comment.comment }}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    <i style="font-size: 12px; color: grey">
                                                                        {{ comment.commentType }}
                                                                    </i>
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle>
                                                                    <i
                                                                        style="font-size: 12px; color: grey">{{$Format.formatDate(comment.createdAt).dateTime}}</i>
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>


                                                            <!-- Current User's Avatar on Right -->
                                                            <v-list-item-avatar
                                                                v-if="comment.createdBy && comment.createdBy.id === $store.state.user.id">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-avatar v-bind="attrs" v-on="on"
                                                                            style="cursor: pointer" color="secondary"
                                                                            size="48" class="mt-0 pt-0">
                                                                            <span v-if="!comment.createdBy.avatar"
                                                                                class="primaryText--text text-h5">
                                                                                {{$Format.avatarAbbreviation(comment.createdBy.firstname
                                                                                + ' ' + comment.createdBy.surname)}}
                                                                            </span>
                                                                            <img v-else :src="comment.createdBy.avatar"
                                                                                referrerpolicy="no-referrer" />
                                                                        </v-avatar>
                                                                    </template>
                                                                    <span style="font-size: 12px">{{
                                                                        comment.createdBy.firstname }} {{
                                                                        comment.createdBy.surname }}</span>
                                                                </v-tooltip>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                                <v-card flat width="inherit"
                                                    style="background-color: var(--v-greyRaised-base) !important; overflow-y: hidden;">
                                                    <div style="display: flex; justify-content: center; align-content: center;"
                                                        class="mb-2">
                                                        <v-chip small outlined class="mx-3"
                                                            @click="commentItem.commentType = 'General'"
                                                            :color="commentItem.commentType == 'General' ? 'tertiary' : ''">General</v-chip>
                                                        <v-chip small outlined class="mx-3"
                                                            @click="commentItem.commentType = 'Invoice'"
                                                            :color="commentItem.commentType == 'Invoice' ? 'tertiary' : ''">Invoice</v-chip>
                                                        <v-chip small outlined class="mx-3"
                                                            @click="commentItem.commentType = 'Query'"
                                                            :color="commentItem.commentType == 'Query' ? 'tertiary' : ''">Query</v-chip>
                                                    </div>
                                                    <div style="display: flex;align-items: center;">
                                                        <v-textarea class="ml-2" no-resize hide-details outlined
                                                            autofocus height="7vh" style="width: 80%; contain: content"
                                                            placeholder="Send a message" v-model="commentItem.comment"
                                                            :value="commentItem.comment">
                                                        </v-textarea>
                                                        <v-btn :loading="savingComment" icon class="ml-3 mr-2"
                                                            @click="commentDoc(item)">
                                                            <v-icon>send</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-card>
                                            </v-card>
                                        </v-menu>
                                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                                            style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            <span :class="item.status == 'Rejected' ? 'rejected' : ''">{{
                                                item[header.value] }}</span>
                                        </v-chip>
                                    </div>


                                    <div v-else>
                                        <v-chip v-if="item[header.value]" small class="primaryText--text" outlined
                                            style="border: none" @click="addToFilter(item[header.value], header.value)">
                                            {{ item[header.value] }}
                                        </v-chip>
                                    </div>
                                </td>
                            </div>
                            <v-divider class="my-0 py-0"></v-divider>
                        </template>
                    </v-virtual-scroll>
                </div>
            </div>
            <div class="container" v-if="!loading && documents.length == 0">
                <h3 style="color: grey">No Results Found</h3>
            </div>

        </v-card-text>
        <v-dialog persistent v-model="documentModal" fullscreen>
            <DocumentModal @close="documentModal = false" :shipment="shipment" :item="shipment" :actions="actions"
                @updateDocument="updateDocument" :bookings="bookings" :documentItem="document" :tasks="documentTasks"
                :documents="documentTypes" :documentTypes="documentTypes" :fileNumber="fileNumber"
                :uploads="documentUploads" :openKey="openKey" />
        </v-dialog>
    </div>
</template>

<script>
import DocumentModal from "../SystemComponents/DocumentModal.vue";
export default  {
    components: {
        DocumentModal,
    },
    props: ['week', 'kpiCategory', 'filter', 'opened'],
    data: () => ({
        search: '',
        loading: false,
        displayData: [],
        isDialog: false,
        fileTimeout: undefined,
        fileRequest: undefined,
        documents: [],
        shipmentCounts: null,
        loadingFiles: false,
        sortBy: false,
        sortDesc: false,
        filters:{},
        updateFilterValue: 0,
        filteredValues: [],
        headers: [
            {
                text: "User",
                value: "assignedDocumentUserName",
                align: 'center',
                width: "200px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Customer",
                value: "customerName",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Shipper",
                value: "shipperName",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Consignee",
                value: "consigneeName",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "File No.",
                value: "fileNumber",
                align: 'center',
                width: "180px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Vessel & Voyage",
                value: "vesselVoyage",
                align: 'center',
                width: "200px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Party",
                value: "responsiblePartyType",
                align: 'center',
                width: "90px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Document",
                value: "document",
                align: 'center',
                width: "220px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Status",
                value: "status",
                align: 'left',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Reference",
                value: "reference",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Submitted",
                value: "submittedDate",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Re-Submitted",
                value: "resubmitDate",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "Completed",
                value: "completedDate",
                align: 'center',
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "POL",
                value: "portOfLoadValue",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "FD",
                value: "finalDestinationValue",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "ETD",
                value: "etd",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
            {
                text: "ETA",
                value: "eta",
                align: 'center',
                width: "120px",
                disableSort: false,
                disableFilter: false,
                show: true,
            },
        ],
        searchHeaders: {},
        documentLoading: null,
        document: {},
        documentTypes: [],
        openFiles: [],
        documentTasks: [],
        documentUploads: [],
        actions: [],
        shipment: {},
        bookings: [],
        filteredResults: [],
        fileNumber: '',
        openKey: 5000,
        documentModal: false,
        loadingComments: false,
        chatOpen: null,
        commentItem : {},
        savingComment : false,
        commentModal : false,
        searchKey: 12,

    }),
    mounted() {

    },
    async created() {
        if(this.opened) {
            this.getDocs()
        }
        this.headers.forEach(header => {
            this.filters[header.value] = [];
        });
            
    },
    watch: {
        search() {
            this.calculateFilteredResults();
        },
        filters: {
            handler() {
                this.calculateFilteredResults();
            },
            deep: true,
        },
        opened: {
            handler(val) {
                if(val && val == true) {
                    this.headers.forEach(header => {
                        this.filters[header.value] = [];
                    });
                    
                    this.getDocs()
                }
            }
        }
    },
    computed: {
        calculatedHeight() {
            let height = window.innerHeight;
            return height * 0.78 + "px";
        },
        tableWidth() {
            let result = 0;
            this.headers.filter(x => x.show).forEach((x) => {
                result += parseInt(x.width ? x.width.replace("px", "") : 150);
            });
            return result + "px";
        },
        documentsMap() {
            return this.filteredResults.filter(x => {
                let searchFilter = true;
                if (this.search) {
                    searchFilter = [ x.assignedDocumentUserName, x.fileNumber, x.reference, x.etd, x.eta, x.responsiblePartyType,
                        x.customerName, x.buyerName, x.shipperName, x.consigneeName, x.movementType,
                        x.vesselVoyage, x.document, x.portOfLoadValue, x.portOfLoadCity,
                        x.finalDestinationValue, x.finalDestinationCity, x.status,
                        x.submittedDate, x.resubmitDate, x.completedDate
                    ].some(field => field && field.toLowerCase().includes(this.search.toLowerCase()));
                }
                return searchFilter;
            }).sort((a, b) => {
                if (this.sortBy) {
                    return this.sortDesc ? (a[this.sortBy] < b[this.sortBy] ? 1 : -1) : (a[this.sortBy] > b[this.sortBy] ? 1 : -1);
                }
                return 0;
            });
        },
    },
    methods: {
        updateDocument() {
            this.getDocs()
            this.documentModal = false
        },
        async getDocs() {
            if(this.fileTimeout) {
                clearTimeout(this.fileTimeout);
            }
            if(this.loadingFiles && this.fileRequest) {
                this.fileRequest.abort()
            }
            this.fileTimeout = setTimeout(async () => {
                this.fileRequest = new AbortController();
                const signal = this.fileRequest.signal
                let filterFinal = null
                if(this.filter && this.filter.vesselVoyage && this.filter.vesselVoyage?.voyage) {
                    filterFinal = {
                        ...this.filter,
                        vesselVoyage: {
                            voyage: this.filter.vesselVoyage.voyage,
                            id: this.filter.vesselVoyage.id,
                        }
                    }
                } else {
                    filterFinal = {...this.filter}
                }
                this.loadingFiles = true;
                let reqBody = {
                    ...filterFinal,
                    kpiCategory: this.kpiCategory,
                }
                if(this.week) {
                    reqBody.weeks = [this.week.text]
                }
                let result = await this.$API.getDashboardDocs({params: reqBody, signal});
                this.documents = result.documents;
                this.shipmentCounts = result.shipmentCounts;
                this.calculateFilteredResults();
                this.loadingFiles = false;
            }, 1000);
        },
        closeFiles() {
            this.documents = []
            this.shipmentCounts = null
            this.isDialog = false
            this.$emit('close')
        },
        async resetFilters() {
            let keys = Object.keys(this.filters);
            keys.forEach((x) => {
                this.filters[x] = [];
                this.searchHeaders[x] = null;
            });
            let result = [];
            keys.forEach((x) => {
            result = [
                ...result,
                ...this.filters[x].map((y) => ({
                field: x,
                fieldName: this.headers.find(z => z.value == x).text,
                value: y,
                })),
            ];
            });
            this.filteredValues = result;
            ['fivePlus','threeToFour','zeroToTwo','zeroToThree','past','openFilesFilter','rejected','overdue','inDanger','withinKPI'].forEach(x => {
                this[x] = false;
            });
            this.headers.find(x => x.value == 'actions').show = false;
            this.updateFilterValue++;           
            this.calculateFilteredResults();
        },
        async viewShipment(item) {
            this.$router.push({
                path: "/documentation/" + item.shipmentReference,
            });
        },
        headerOptions(value) {
            let result = [
                ...new Set(
                this.documents.map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
                ),
            ];
            let uniqueValues = [...new Set(result.map((x) => x.value))];
            if (this.searchHeaders[value]) {
                uniqueValues = uniqueValues.filter(
                (x) => x && x.toLowerCase().includes(this.searchHeaders[value].toLowerCase()));
            }
            result = uniqueValues.map((x) => result.find((y) => y.value === x));
            result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            return result;
        },
        chatColor(comments) {
            let result = ''
            if(comments.length > 0) {
                if(Object.keys(comments[0]).includes('shipmentDocumentTypeComment')) {
                    //check if all the values for shipmentDocumentTypeComment are null. If all are null, then the comments are not related to the document
                    let check = []
                    comments.forEach(x => {
                        if(x.shipmentDocumentTypeComment && x.shipmentDocumentTypeComment.comment) {
                            check.push(x.shipmentDocumentTypeComment.comment)
                        }
                    if(check.length > 0) {
                        result = 'tertiary'
                    } else {
                        result = ''
                    }
                    })
                } else {
                    result = 'tertiary';
                }
            } else {
                result = ''
            }
            return result
        },
        changeSortBy(value) {
            if (this.sortBy == value) {
                if (this.sortDesc) {
                    this.sortBy = null;
                    this.sortDesc = false;
                } else {
                    this.sortDesc = true;
                }
                } else {
                this.sortBy = value;
                this.sortDesc = false;
            }
        },
        addToFilter(value, field) {
            if (this.filters[field].includes(value)) {
                this.filters[field] = this.filters[field].filter((x) => x != value);
            } else {
                this.filters[field].push(value);
            }
            this.updateFilterValue++;
            // localStorage.setItem(
            //     "bookingFilters_" + this.$store.state.currentOrg.id,
            //     JSON.stringify(this.filters)
            // );
            let keys = Object.keys(this.filters);
            let result = [];
            keys.forEach((x) => {
                result = [
                ...result,
                ...this.filters[x].map((y) => ({
                    field: x,
                    fieldName: this.headers.find(z => z.value == x).text,
                    value: y,
                })),
            ];
            });
            this.filteredValues = result;
            this.calculateFilteredResults();
        },
        calculateFilteredResults() {
            let filterKeys = Object.keys(this.filters);
            let filterResult = [];
            filterKeys.forEach((x) => {
                filterResult = [
                ...filterResult,
                ...this.filters[x].map((y) => ({
                    field: x,
                    fieldName: this.headers.find(z => z.value == x).text,
                    value: y,
                })),
            ];
            });
            this.filteredValues = filterResult;
            let result = [...this.documents];
            let keys = Object.keys(this.filters);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (this.filters[key] && this.filters[key].length > 0) {
                result = result.filter((x) => this.filters[key].includes(x[key]));
                }
            }
            this.filteredResults = result;
        },
        async viewDocument(document) {
            console.log('viewDoucment', document)
            if (document) {
                this.documentLoading = document.id
                let [doc, documentTasks, documentUploads, actions, shipment, bookings] = await Promise.allSettled([
                    this.$API.getShipmentDocumentTypeDetail(document.id),
                    this.$API.getShipmentDocumentTasks(document.id),
                    this.$API.getShipmentDocuments(document.shipment.id),
                    this.$API.getShipmentActions(document.shipment.id),
                    this.$API.getSingleShipment(document.shipment.shipmentReference),
                    this.$API.getShipmentBookings(document.shipment.shipmentFileId),
                ])
                this.document = doc.value
                console.log('document', this.document)
                if (this.document.variations) {
                    this.documentTypes = [this.document]
                }
                this.documentTasks = documentTasks.value
                console.log('documentTasks', this.documentTasks)
                this.documentUploads = documentUploads.value
                console.log('documentUploads', this.documentUploads)
                this.actions = actions.value
                console.log('actions', this.actions)
                this.shipment = shipment.value
                console.log('shipment', this.shipment)
                this.bookings = bookings.value
                console.log('bookings', this.bookings)
                this.fileNumber = document.shipment.shipmentFile.fileNumber
                console.log('fileNumber', this.fileNumber)
                setTimeout(() => {
                    this.openKey++
                }, 250)
                this.documentModal = true
                this.documentLoading = null
            }
        },
        async getShipmentDocumentLog(id) {
            this.loadingComments = true;
            try {
                let comments = await this.$API.getShipmentDocumentTypeComments(id);


                this.chatOpen = id;
                comments = JSON.parse(JSON.stringify(comments));
                console.log('Processed comments data:', comments);

                if (comments) {
                    this.files = this.files.map(file => {
                        if (file.id === id) {
                            file.comments = comments;
                        }
                        return file;
                    });
                }
            } catch (error) {
                console.error('Error fetching comments:', error);
            } finally {
                this.loadingComments = false;
                console.log('Loading comments set to false');
            }
        },
        async commentDoc(doc) {
            this.savingComment = true
            if (this.commentItem && !this.commentItem.commentType) {
                this.commentItem.commentType = 'Query'
            }
            console.log('this.commentItem', this.commentItem)
            let comment = {
                shipmentDocumentTypeId: doc.id,
                comment: this.commentItem.comment,
                commentType: this.commentItem.commentType,
                shipmentId: doc.shipmentId,
                createdById: this.$store.state.user.id,
            }
            let res = await this.$API.addShipmentDocumentTypeComment(comment)
            if(res) {
                comment.createdAt = res.createdAt
                comment.user = this.$store.state.user;
                comment.shipmentDocumentTypeComment = {
                    comment: this.commentItem.comment,
                    commentType: this.commentItem.commentType,
                }
                this.documents = this.documents.map(x => {
                    if(x.id == doc.id) {
                        x.comments.push(comment);
                    }
                    return x;
                });
            }   else {
                this.$message({
                    type: "error",
                    message: `Message could not go through. Please check your connection and try again.`,
                });
            }
            // if (res && this.commentItem.commentType == 'General' || this.commentItem.commentType == 'Invoice') {
            //   await this.getDocumentLog(this.documentItem.id)
            // }
            this.commentItem = {}
            this.savingComment = false
            this.commentModal = false
            },
        
    },
}
</script>

<style>

</style>